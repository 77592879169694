import Vector from "./vector";
import InstagramSticker, {InstagramStickerCanvasPen} from "./instagram_sticker";
import PKLogo, {PKLogoCanvasPen} from "./pk_logo";
import PKSymbol, {PKSymbolCanvasPen} from "./pk_symbol";
import PKText, {PKTextCanvasPen} from "./pk_text";
import Star, {StarCanvasPen} from "./star";
import Slogan, {SloganCanvasPen} from "./slogan";
export default class SliderBehavior {
   constructor(state, display) {
      this.state = state;
      this.display = display;
   }

   init() {
      this.initElements();

      this.initEvents();
   }

   exec(f, t) {
      this.state.update(f, t)
      this.display.sync(this.state, f);
   }

   initEvents() {
       this.display.canvas.addEventListener('mousemove', function(e) {
           e.stopPropagation();

           this.display.canvas.style.cursor = 'default';

           var cursor = new Vector(
               e.offsetX || e.originalEvent.layerX,
               e.offsetY || e.originalEvent.layerY
           )

           this.state.elements.forEach(function(e) {
               let v = new Vector(cursor.x, cursor.y);

               if (!!!e.onEvent)
                   return;

               e.onEvent('mousemove', v);
           }.bind(this));
       }.bind(this));

       let clickEvent = function(event) {
           this.state.elements.forEach(function(e) {
               let v = new Vector(event.x, event.y);

               if (!!!e.onEvent)
                   return;

               e.onEvent('click', v);
           }.bind(this));
       }.bind(this)

        this.display.canvas.addEventListener('click', clickEvent);

      this.display.win.bind('keyup', function(event) {
         this.state.elements.forEach(function(e) {
            if (!!!e.onEvent)
               return;

            e.onEvent('keyup');
         });
      }.bind(this))

      this.display.win.bind('keydown', function(event) {
         this.state.elements.forEach(function(e) {
            if (!!!e.onEvent)
               return;

            if (event.keyCode == 37)
               e.onEvent('keydown__arrow_left');
            if (event.keyCode == 39)
               e.onEvent('keydown__arrow_right');
            if (event.keyCode == 38)
               e.onEvent('keydown__arrow_up');
            if (event.keyCode == 40)
               e.onEvent('keydown__arrow_down');
            if (event.keyCode == 32)
               e.onEvent('keydown__space');
         });
      }.bind(this))
   }

    initElements() {
        for (let k = 0; k < Math.floor(this.display.w/4); k++) {
            let s = new Star({
                pen: new StarCanvasPen(this.display),
                position: new Vector(Math.random()*this.display.w, Math.random()*this.display.h)
            });

            this.state.elements.push(s);

            s.onEvent('keydown__arrow_left');
        }

        let props = function(d, f) {
            let n = 4
            let freq = n*(f % Math.round(360/n))*Math.PI/180
            let color = 'hsl(' + (150 + f % 60) + ', 100%, 70%)'
            let cbx = Math.sin(freq)*0.15 + 0.3
            let cex = Math.cos(freq)*0.15 + 0.3

            let bcbx = -Math.cos(freq)*4 + 1
            let bcex = -Math.sin(freq)*4 + 1

            let gcby = -Math.cos(freq)*0.1 + 0.2
            let gcey = -Math.cos(freq)*0.1 + 0.2

            let dcby = -Math.sin(freq)*0.1 + 0.2
            let dcey = -Math.sin(freq)*0.1 + 0.2

            let accby = Math.sin(freq)*0.1 + 0.2
            let accey = Math.sin(freq)*0.1 + 0.2

            return {
                text: {
                    concavity: {
                        cbx: 0.2,
                        cex: 0.2,
                        cby: accby,
                        cey: accey,
                        ratio: 1
                    }
                },
                symbol: {
                    bezis: {
                        alpha: {
                            concavity: {
                                cbx: cbx,
                                cex: cex,
                                cby: 0.1,
                                cey: 0.1,
                                ratio: 1
                            }
                        },
                        beta: {
                            concavity: {
                                cbx: bcbx,
                                cex: bcex,
                                cby: 1,
                                cey: 1,
                                ratio: 2
                            }
                        },
                        delta: {
                            concavity: {
                                cbx: 0.2,
                                cex: 0.2,
                                cby: dcby,
                                cey: dcey,
                                ratio: -0.1
                            }
                        },
                        gama: {
                            concavity: {
                                cbx: 0.3,
                                cex: 0.3,
                                cby: gcby,
                                cey: gcey,
                                ratio: 0.2
                            }
                        }
                    }
                }
            }
        };
        let size = this.display.w*0.95;
        let spacing = this.display.h*0.1;
        let logo = new PKLogo({
            pen: new PKLogoCanvasPen(this.display),
            begin: new Vector(
                (this.display.w - size)/2, 70
            ),
            height: size,
            style: PKLogo.STYLE__DARK,
            alignment: PKLogo.ALIGNMENT__BOTTOM
        }, props)

        if (this.display.w < 540 && this.display.h >= 540 && this.display.h <= 1024) {
            this.state.elements.push(logo);
        } else if (this.display.w >= 540 && this.display.w <= 1024 && this.display.h >= 720 && this.display.h < 1400) {
            size = this.display.h*0.4;
            logo = new PKLogo({
                pen: new PKLogoCanvasPen(this.display),
                begin: new Vector(
                    (this.display.w - size)/2, 40
                ),
                height: size,
                style: PKLogo.STYLE__DARK,
                alignment: PKLogo.ALIGNMENT__BOTTOM
            }, props)

            this.state.elements.push(logo);
        } else if (this.display.w > 540 && this.display.h >= 350) {
            size = this.display.h*0.3;
            logo = new PKLogo({
                pen: new PKLogoCanvasPen(this.display),
                begin: new Vector(
                    this.display.w*0.1, this.display.h*0.1
                ),
                height: size,
                style: PKLogo.STYLE__DARK,
                alignment: PKLogo.ALIGNMENT__LEFT
            }, props)

            this.state.elements.push(logo);
        }

        this.state.elements.push(new Slogan({ pen: new SloganCanvasPen(this.display),
            center: new Vector(5000, 5000),
            content: "faço sites de alto impacto visual e soluções web sob demanda 👻",
            maxWidth: this.display.w > 1024 ? this.display.w > 1366 ? this.display.w*0.5: this.display.w*0.7 : this.display.w*0.95, // 390 mobi
            alignX: !(this.display.w > 1024),
            alignY: true,
            lineHeight: this.display.w > 1024 ? this.display.w > 1366 ? 0.020*this.display.w : 0.03*this.display.w : 22, // 30 mobi
            weight: 400,
            color: 'rgba(255,255,255,0.9)',
            lineSpacing: this.display.w > 1024 ? this.display.w > 1366 ? 0.020*this.display.w : 0.03*this.display.w : 22,
            after: function(slogan, display) {
                if (display.w > 1024) {
                    slogan.center.x = logo.begin.x + slogan.width/2
                    slogan.center.y = logo.begin.y + logo.height + slogan.height / 2 + spacing
                } else {
                    slogan.center.x = display.w/2
                    slogan.center.y = logo.begin.y + logo.height + slogan.height / 2 + spacing/2
                }

                slogan.dim()
                slogan.pos()
            },
            builder: function(display, slogan, word, content) {
                if (content == 'soluções' || content == 'web') {
                    word.setProps({
                        color: PKSymbol.NEON__PARAMS.bezis.alpha.strokeColor,
                        weight: 900
                    })
                    word.dim();
                }

                if (content == 'futuro') {
                    word.setProps({
                        stroke: false,
                        strokeWidth: 2,
                        fill: true,
                        weight: 900,
                        color: PKSymbol.NEON__PARAMS.bezis.delta.strokeColor
                    })
                    word.setBorder({
                        enabled: ['bottom'],
                        props: {
                            bottom: {
                                padding: 5,
                                strokeWidth: 5,
                                color: PKSymbol.NEON__PARAMS.bezis.delta.strokeColor,
                                angle: 2,
                                shadow: true,
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowOffsetY: -4,
                                shadowColor: PKSymbol.NEON__PARAMS.bezis.delta.shadowColor,
                                concavity: {
                                    cbx: 0.2,
                                    cex: 0.6,
                                    cby: 0.1,
                                    cey: 0.1,
                                    ratio: 0.2
                                }
                            }
                        }
                    });
                }

                if (content == 'inventamos') {
                    word.wrapX = true;
                    word.setBorder({
                        enabled: ['left'],
                        props: {
                            all: {
                                strokeWidth: 6,
                                padding: 10,
                                margin: 10,
                                color: PKSymbol.NEON__PARAMS.bezis.gama.strokeColor,
                                concavity: {
                                    cbx: 0.3,
                                    cex: 0.5,
                                    cby: 0.4,
                                    cey: 0.2,
                                    ratio: 1
                                }
                            }
                        }
                    });
                }

                if (content == 'sites') {
                    word.setProps({
                        weight: 900
                    })
                }

                if (content == 'imita') {
                    word.setProps({
                        weight: 900,
                    })

                    word.setBorder({
                        enabled: ['bottom'],
                        props: {
                            all: {
                                strokeWidth: 4,
                                padding: 4,
                                margin: 0,
                                color: PKSymbol.NEON__PARAMS.bezis.gama.strokeColor,
                                concavity: {
                                    cbx: 0.2,
                                    cex: 0.2,
                                    cby: 0.2,
                                    cey: 0.2,
                                    ratio: 1
                                }
                            }
                        }
                    });
                }
            }}));

        let bottomSpacing = isMobile.iOS() ? 60 : isMobile.any() ? 40 : 80
        let polemk = new InstagramSticker({
                pen: new InstagramStickerCanvasPen(this.display),
                begin: new Vector(isMobile.any() ? 10 : logo.begin.x, this.display.h - bottomSpacing),
                content: "polemk",
                url: "https://instagram.com/polemk",
                after: function(e, d) {

                }
            }, {
                mode: InstagramSticker.MODE__MENTION,
                style: InstagramSticker.STYLE__INSTA,
                size: this.display.w < 360 ? 20 : 24,
                angle: 0
            }
        );
        this.state.elements.push(polemk);

        let gui = new InstagramSticker({
                pen: new InstagramStickerCanvasPen(this.display),
                begin: new Vector( 5000, 5000),
                content: "gui.migotti",
                url: "https://instagram.com/gui.migotti",
                after: function(e, d) {

                }
            }, {
                mode: InstagramSticker.MODE__MENTION,
                style: InstagramSticker.STYLE__INSTA,
                size: this.display.w < 360 ? 20 : 24,
                angle: 0
            }
        );
        this.state.elements.push(gui);

        let whats = new InstagramSticker({
                pen: new InstagramStickerCanvasPen(this.display),
                begin: new Vector( 5000, 5000),
                content: "whats",
                url: 'https://api.whatsapp.com/send?phone=5549988134608',
                after: function(e, d) {
                    document.fonts.ready.then(function () {
                        gui.updateBegin(polemk.begin.x + polemk.width + 10, polemk.begin.y);
                        e.updateBegin(gui.begin.x + gui.width + 10, polemk.begin.y);
                    })
                }
            }, {
                mode: InstagramSticker.MODE__LINK,
                style: InstagramSticker.STYLE__SINGLE,
                size: this.display.w < 360 ? 20 : 24,
                angle: 0
            }
        );
        this.state.elements.push(whats);
    }
}

