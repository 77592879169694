import Vector from './vector.js';
export default class Bezi {

    static CONFIG = {
        pen: null,
        begin: new Vector(-500, -500),
        end: new Vector(-500, -500),
        cb: new Vector(-500, -500),
        ce: new Vector(-500, -500),
        angle: 0,
        length: 100,
        debugControls: false,
        debugBaseline: false,
        debugRect: false,
    }

    static DEFAULTS = {
        lineCap: 'round',
        concavity: null,

        strokeWidth: 2,
        strokeColor: 'rgb(255,255,255)',

        shadow: false,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowBlur: 0,
        shadowColor: 'rgba(255,255,255,0.7)',
    }

    static CONCAVITY_DEFAULTS = {
        cbx: 0.25,
        cex: 0.25,
        cby: 1,
        cey: 1,
        ratio: 0.1
    }

    constructor(config, props) {
        Object.assign(this, Bezi.CONFIG, config)

        this.props = Object.assign({}, Bezi.DEFAULTS)

        this.setProps(props);
    }

    init() {
        //this.dim();
    }

    dim() {
        let conc = this.props.concavity

        let angle = this.angle * Math.PI/180
        this.end = this.begin.projection(-this.angle, this.length)

        let cb = new Vector(0,0)
        let ce = new Vector(0,0)

        cb.x = this.begin.x + this.length * conc.cbx * Math.cos(angle) + this.length * conc.ratio * conc.cby * Math.cos(angle + Math.PI/2)
        cb.y = this.begin.y - this.length * conc.cbx * Math.sin(angle) - this.length * conc.ratio * conc.cby * Math.sin(angle + Math.PI/2)

        ce.x = this.begin.x + this.length * (1 - conc.cex) * Math.cos(angle) + this.length * conc.ratio * conc.cey * Math.cos(angle + Math.PI/2)
        ce.y = this.begin.y - this.length * (1 - conc.cex) * Math.sin(angle) - this.length * conc.ratio * conc.cey * Math.sin(angle + Math.PI/2)

        this.cb = cb;
        this.ce = ce;

        this.rect = {
            tl: new Vector(
                Math.min(this.begin.x, this.end.x, this.cb.x, this.ce.x) - this.props.strokeWidth/2,
                Math.min(this.begin.y, this.end.y, this.cb.y, this.ce.y) - this.props.strokeWidth/2
            ),
            tr: new Vector(
                Math.max(this.begin.x, this.end.x, this.cb.x, this.ce.x) + this.props.strokeWidth/2,
                Math.min(this.begin.y, this.end.y, this.cb.y, this.ce.y) - this.props.strokeWidth/2
            ),
            bl: new Vector(
                Math.min(this.begin.x, this.end.x, this.cb.x, this.ce.x) - this.props.strokeWidth/2,
                Math.max(this.begin.y, this.end.y, this.cb.y, this.ce.y) + this.props.strokeWidth/2
            ),
            br: new Vector(
                Math.max(this.begin.x, this.end.x, this.cb.x, this.ce.x) + this.props.strokeWidth/2,
                Math.max(this.begin.y, this.end.y, this.cb.y, this.ce.y) + this.props.strokeWidth/2
            )
        }
    }

    setProps(props) {
        if (typeof(props) == 'function') {
            this.animator = props;

            Object.assign(this.props, this.animator(this.pen.display, 0))
        } else {
            this.animator = null;

            Object.assign(this.props, props)
        }

        this.prepareConcavityProps(this.props)

        this.dim();
    }

    prepareConcavityProps(props) {
        if (!props.concavity)
            return;

        let c = props.concavity

        if (typeof(c) == 'function')
            this.concavityAnimator = c;
        else
            this.concavityAnimator = null;

        if (typeof(c) == 'number') {
            props.concavity = Object.assign({}, Bezi.CONCAVITY_DEFAULTS, {ratio: c})
        } else {
            props.concavity = Object.assign({}, Bezi.CONCAVITY_DEFAULTS, props.concavity)
        }
    }

    updateProps(display, frame) {
        if (this.animator != null) {
            Object.assign(this.props, this.animator(display, frame))

            this.dim();
        }

        if (this.concavityAnimator != null) {
            Object.assign(this.props.concavity, this.concavityAnimator(display, frame))

            this.dim();
        }
    }

    updateBegin(x, y) {
        if (x != null)
            this.begin.x = x;

        if (y != null)
            this.begin.y = y;

        this.dim()
    }

    getBegin() {
        return this.begin.dup;
    }

    get width() {
        if (this.rect === null)
            return 0;

        return this.rect.tr.x - this.rect.tl.x + this.props.strokeWidth;
    }

    get height() {
        if (this.rect === null)
            return 0;

        return this.rect.bl.y - this.rect.tl.y + this.props.strokeWidth;
    }

    get center() {
        if (this.rect === null)
            return null;

        let c = this.rect.tl.dup()

        c.x += this.width/2
        c.y += this.height/2

        return c;
    }

    update(uid, frame, delta, state) {
        this.updateProps(state.display, frame);

        return true;
    }

    draw(display, frame) {
        this.pen.draw(this, frame);
    }
}

export class BeziCanvasPen {
    constructor(display) {
        this.display = display;
        this.ctx = display.ctx;
    }

    draw(element, frame) {
        this.ctx.save()
        this.ctx.strokeStyle = element.props.strokeColor;
        this.ctx.lineWidth = element.props.strokeWidth;
        this.ctx.lineCap = element.props.lineCap;
        this.ctx.fillStyle = element.props.strokeColor;

        if (element.props.shadow) {
            this.ctx.shadowOffsetX = element.props.shadowOffsetX;
            this.ctx.shadowOffsetY = element.props.shadowOffsetY;
            this.ctx.shadowBlur = element.props.shadowBlur;
            this.ctx.shadowColor = element.props.shadowColor;
        }
        
        this.ctx.beginPath();
        
        let b = element.begin
        let e = element.end
        let cb = element.cb
        let ce = element.ce
 
        this.ctx.moveTo(b.x, b.y)
        this.ctx.bezierCurveTo(cb.x, cb.y, ce.x, ce.y, e.x, e.y);
        this.ctx.stroke();

        if (element.debugControls) {
            this.ctx.strokeStyle = 'yellow';
            this.ctx.lineWidth = 2;
            this.ctx.setLineDash([2,10])
            this.ctx.beginPath();
            this.ctx.moveTo(b.x, b.y)
            this.ctx.lineTo(cb.x, cb.y)
            this.ctx.stroke();

            this.ctx.strokeStyle = 'yellow';
            this.ctx.lineWidth = 2;
            this.ctx.beginPath();
            this.ctx.setLineDash([0,0])
            this.ctx.arc(cb.x, cb.y, 5, 0, 2*Math.PI)
            this.ctx.stroke();

            this.ctx.strokeStyle = 'grey';
            this.ctx.lineWidth = 2;
            this.ctx.setLineDash([2,10])
            this.ctx.beginPath();
            this.ctx.moveTo(e.x, e.y)
            this.ctx.lineTo(ce.x, ce.y)
            this.ctx.stroke();

            this.ctx.strokeStyle = 'grey';
            this.ctx.lineWidth = 2;
            this.ctx.setLineDash([0,0])
            this.ctx.beginPath();
            this.ctx.arc(ce.x, ce.y, 5, 0, 2*Math.PI)

            this.ctx.stroke();
        }
        
        this.ctx.restore();


        if (element.debugBaseline) {
            // ponto begin
            this.ctx.save()
            this.ctx.strokeStyle = 'blue';
            this.ctx.lineWidth = 5;
            this.ctx.beginPath();
            this.ctx.arc(element.begin.x, element.begin.y, 5, 0, 2*Math.PI)
            this.ctx.stroke();
            this.ctx.restore()

            // // ponto end
            this.ctx.save()
            this.ctx.strokeStyle = 'magenta';
            this.ctx.lineWidth = 5;
            this.ctx.beginPath();
            this.ctx.arc(element.end.x, element.end.y, 5, 0, 2*Math.PI)
            this.ctx.stroke();
            this.ctx.restore()

            // linha original
            this.ctx.save()
            this.ctx.strokeStyle = 'rgba(255,255,255, 1)';
            this.ctx.setLineDash([5,10])
            this.ctx.lineWidth = 2;
            this.ctx.lineCap = 'round';
            this.ctx.beginPath();
            this.ctx.moveTo(element.begin.x, element.begin.y)
            this.ctx.lineTo(element.end.x, element.end.y)
            this.ctx.stroke();
            this.ctx.restore()

            // linha original no angulo 0
            // let proj = element.begin.projection(0, element.length)
            // this.ctx.save()
            // this.ctx.strokeStyle = 'rgba(255,255,255, 1)';
            // this.ctx.setLineDash([2,10])
            // this.ctx.lineWidth = 1;
            // this.ctx.lineCap = 'round';
            // this.ctx.beginPath();
            // this.ctx.moveTo(element.begin.x, element.begin.y)
            // this.ctx.lineTo(proj.x, proj.y)
            // this.ctx.stroke();
            // this.ctx.restore()

            // ponto central no angulo 0
            // this.ctx.save()
            // this.ctx.strokeStyle = 'red';
            // this.ctx.lineWidth = 5;
            // this.ctx.beginPath();
            // this.ctx.arc(element.begin.x + (w/2), element.begin.y, 5, 0, 2*Math.PI)
            // this.ctx.stroke();
            // this.ctx.restore()
        }

        if (element.debugRect) {
            this.ctx.save()
            this.ctx.strokeStyle = 'rgba(255,255,255, 1)';
            this.ctx.setLineDash([2,10])
            this.ctx.lineWidth = 2;
            this.ctx.lineCap = 'round';
            this.ctx.beginPath();
            this.ctx.moveTo(element.rect.tl.x, element.rect.tl.y)
            this.ctx.lineTo(element.rect.tr.x, element.rect.tr.y)
            this.ctx.lineTo(element.rect.br.x, element.rect.br.y)
            this.ctx.lineTo(element.rect.bl.x, element.rect.bl.y)
            this.ctx.lineTo(element.rect.tl.x, element.rect.tl.y)
            this.ctx.closePath();
            this.ctx.stroke();
            this.ctx.restore()
        }
    }
}







