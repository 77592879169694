import Vector from './vector.js';
import Word, {WordCanvasPen} from "./word";
import Line, {LineCanvasPen} from "./line";

export default class Slogan {
    constructor(config) {
        Object.assign(
            this,
            {
                pen: null,
                type: 'slogan',
                begin: new Vector(0, 0),
                center: new Vector(0, 0),
                lines: [],
                width: 0,
                height: 0,
                lineHeight: 50,
                lineSpacing: 20,
                letterSpacing: 0,
                font: 'Livetat SOF',
                maxWidth: 320,
                weight: 500,
                color: 'white',
                content: "should be provided",
                words: [],
                alignX: true,
                alignY: false,
                builder: null,
                after: null,
                angle: 0
            },
            config
        )

        this.init();
    }

    /* tem que rodar o initWords duas vezes n sei pq caralha */
    init() {
        /* a posição não deve ser obrigatória para o dimensionamento */
        this.outside = new Vector(-this.pen.display.w, -this.pen.display.h)

        /* inicializa as palavras e repassa props */
        this.initWords();

        /* estrutura as palavras em linhas e dimensiona cada linha */
        this.initLines();

        /* definição do begin com relação ao centro (externo) */
        this.dim();

        /* posicionamento das linhas e palavras */
        this.pos();

        /* os tamanhos mudam após o load da fonte
         * FIXME: melhorar melhorar no futuro */
        // console.warn('antes da fonte');
        document.fonts.ready.then(function () {
            // console.warn('dps da fonte');
            this.initWords();
            this.initLines();
            this.dim();
            this.pos();
        }.bind(this))

        /* config do callback externo para ops que dependem de dimensões */
        if (!!this.after)
            setTimeout(function () {
                this.after(this, this.pen.display);
            }.bind(this), 160);
    }

    initWords() {
        this.words = []

        this.content.split(" ").forEach(function (c, k) {
            let word = new Word(
         {
                    begin: new Vector(this.outside.x, this.outside.y),
                    pen: new WordCanvasPen(this.pen.display),
                    content: c
                },
                {
                    font: this.font,
                    color: this.color,
                    size: this.lineHeight,
                    weight: this.weight,
                    letterSpacing: this.letterSpacing
                }
            );

            if (!!this.builder)
            this.builder(this.pen.display, this, word, c);

            word.dim();

            this.words.push(word);
        }.bind(this));
    }

    initLines() {
        this.lines = [];

        this.lines.push(new Line({
            begin: new Vector(this.outside.x, this.outside.y),
            pen: new LineCanvasPen(this.pen.display),
            alignY: this.alignY,
            maxWidth: this.maxWidth,
            id: 1
        }))

        for (let k = 0; k < this.words.length; k++) {
            let line = this.lines[this.lines.length - 1];
            let word = this.words[k];

            if (!line.push(word)) {
                let nl = new Line({
                    begin: new Vector(this.outside.x, this.outside.y),
                    alignY: this.alignY,
                    pen: new LineCanvasPen(this.pen.display),
                    maxWidth: this.maxWidth,
                    id: this.lines.length
                });

                this.lines.push(nl);

                nl.push(word);
            }
        }
    }

    dim(recursive) {
        if (this.lines.length == 0)
            return;

        if (!!recursive)
            this.lines.forEach(function (line) {
                line.dim(true);
            });

        this.width = this.findBiggerLineWidth();
        this.height = this.calcTotalHeight();
        this.begin = new Vector(this.center.x - this.width / 2, this.center.y + this.height / 2);
    }

    pos() {
        let cursor = this.begin.y - this.height;
        this.lines.forEach(function (l, k) {
            let s = k != 0 ? this.lineSpacing : 0;
            let bx = this.begin.x;
            let by = cursor + l.height + s;

            if (this.alignX && l.width < this.width)
                bx += (this.width - l.width) / 2;

            l.begin.x = bx;
            l.begin.y = by;
            l.pos();

            cursor += l.height + s;
        }.bind(this));
    }

    calcTotalHeight() {
        let height = 0;

        this.lines.forEach(function (l, k) {
            height += l.height + (k != 0 ? this.lineSpacing : 0);
        }.bind(this));

        return height;
    }

    findBiggerLineWidth() {
        let max = 0;

        this.lines.forEach(function (l) {
            if (l.width > max)
                max = l.width;
        });

        return max;
    }



    decorate(content, decorator) {
        for (let n = 0; n < this.words.length; n++) {
            let word = this.words[n];

            /* FIXME: replacing array item inside iterator */
            if (word.content === content) {
                this.words[n] = decorator;
            }
        }
    }

    find(word) {
        for (let n = 0; n < this.words.length; n++) {
            let w = this.words[n];

            if (w.content === word)
                return w;
        }

        return null;
    }


    swimming(frame, state) {
        if (frame % 360 < 220) {
            state.elements.forEach(function(e) {
                if (e.type == 'star') {
                    e.state = 'down';
                }
            });
            let f = 15*(frame % 360);
            this.begin.y += 1*Math.cos(f*(Math.PI/180));
            this.lineSpacing += 0.5*Math.sin(f*(Math.PI/180));
            this.pos();
        } else {
            if (frame % 360 == 220) {
                this.dim();
                this.pos();
            }
            if (frame % 2 == 0) {
                let f = 8*(frame % 360);
                this.begin.y += 0.5*Math.cos(f*(Math.PI/180));
                this.lineSpacing += 0.25*Math.sin(f*(Math.PI/180));
                this.pos();
            }
            state.elements.forEach(function(e) {
                if (e.type == 'star') {
                    e.state = 'idle';
                }
            });
        }
    }



    /* TODO: repassar o false do update das childs para o estado */
    update(uid, frame, delta, state) {
        // this.swimming(frame, state);

        this.lines.forEach(function(l) {
            l.update(uid, frame, delta, state);
        });

        return true;
    }

    draw(display, frame) {
        this.pen.draw(this, frame);
    }
}

export class SloganCanvasPen {
    constructor(display) {
        this.display = display;
        this.ctx = display.ctx;
    }

    draw(element, frame) {
        if (element.angle != 0) {
            this.ctx.save();
            this.ctx.rotate(-element.angle*Math.PI/180);

            for (let k = 0; k < element.lines.length; k++)
                element.lines[k].draw(this.display, frame);

            this.ctx.restore();
        } else {
            for (let k = 0; k < element.lines.length; k++)
                element.lines[k].draw(this.display, frame);
        }


        // /* center ref */
        // this.ctx.save();
        // this.ctx.strokeStyle = 'pink';
        // this.ctx.beginPath();
        // this.ctx.moveTo(element.center.x, element.center.y)
        // this.ctx.lineTo(element.center.x - 100, element.center.y)
        // this.ctx.moveTo(element.center.x, element.center.y)
        // this.ctx.lineTo(element.center.x + 100, element.center.y)
        // this.ctx.stroke();
        // this.ctx.restore();
        //
        // this.ctx.save();
        // this.ctx.strokeStyle = 'red';
        // this.ctx.beginPath();
        // this.ctx.moveTo(element.center.x, element.center.y)
        // this.ctx.lineTo(element.center.x, element.center.y - 100)
        // this.ctx.moveTo(element.center.x, element.center.y)
        // this.ctx.lineTo(element.center.x, element.center.y + 100)
        // this.ctx.stroke();
        // this.ctx.restore();

        /* rect do slogan */
        // this.ctx.save();
        // this.ctx.strokeStyle = 'blue';
        // this.ctx.beginPath();
        // this.ctx.moveTo(element.begin.x, element.begin.y)
        // this.ctx.lineTo(element.begin.x + element.width, element.begin.y)
        // this.ctx.lineTo(element.begin.x + element.width, element.begin.y - element.height)
        // this.ctx.lineTo(element.begin.x, element.begin.y - element.height)
        // this.ctx.lineTo(element.begin.x, element.begin.y)
        // this.ctx.stroke();
        // this.ctx.restore();
    }
}