import Vector from "./vector";
import WordBackground from "./word_background";
import WordBorder from "./word_border";

export default class WordCustomizer {
    constructor(word, props) {
        Object.assign(
            this,
            {
                custom: null
            },
            props
        )

        this.word = word;
        this.begin = word.getBegin().dup;
        this.border = new WordBorder(word, props.border);
        this.background = new WordBackground(word, props.background);

        this.init();
    }

    init() {
        /* não se dimensiona na construção pq depende do word */
    }

    dim() {
        if (this.hasBackground)
            this.background.dim();

        if (this.hasCustom)
            this.custom.dim();

        if (this.hasBorder)
            this.border.dim();

        this.totalWidth = this.word.textWidth + this.offsetLeft + this.offsetRight;
        this.totalHeight = this.word.textHeight + this.offsetTop + this.offsetBottom;
        this.center = new Vector(this.begin.x + this.totalWidth / 2, this.begin.y - this.totalHeight / 2);
        this.end = new Vector(this.begin.x + this.totalWidth, this.begin.y);
    }

    setCustom(custom) {
        this.custom = custom

        this.custom.dim();
    }

    /* TODO: cada um se redimensiona ou o dim daqui é que comanda? */
    setProps(props) {
        if (!!props.border)
            this.border.setProps(props.border);

        if (!!props.background)
            this.background.setProps(props.background);

        if (!!props.custom)
            this.custom.setProps(props.custom);

        this.dim();
    }

    /*
     * FIXME: o customizer calcula com base no begin do word e os elementos internamente tbm mas eles precisam do begin
     * do texto e não do begin da word pra se posicionar, aí ficou estranho
     */
    updateBegin(x, y) {
        if (x != null)
            this.begin.x = x - (this.word.wrapX ? 0 : this.offsetLeft);

        if (y != null)
            this.begin.y = y + (this.word.wrapY ? 0 : this.offsetBottom);

        let bx = x + (this.word.wrapX ? this.offsetLeft : 0)
        let by = y - (this.word.wrapY ? this.offsetBottom : 0)

        if (this.hasBackground)
            this.background.updateBegin(bx, by);

        if (this.hasCustom)
            this.custom.updateBegin(bx, by);

        if (this.hasBorder)
            this.border.updateBegin(bx, by);

        this.dim();
    }

    getBegin() {
        return this.begin;
    }

    get width() {
        return this.totalWidth;
    }

    get height() {
        return this.totalHeight;
    }

    get spacing() {
        return this.word.textSpacing; // TODO: calcular o espaçamento compensando bordas
    }

    get offsetLeft() {
        let offsets = []

        if (this.hasBackground)
            offsets.push(this.background.offsetLeft);

        if (this.hasCustom)
            offsets.push(this.custom.offsetLeft);

        if (this.hasBorder)
            offsets.push(this.border.offsetLeft);

        return this.calcMax(offsets);
    }

    get offsetBottom() {
        let offsets = []

        if (this.hasBackground)
            offsets.push(this.background.offsetBottom);

        if (this.hasCustom)
            offsets.push(this.custom.offsetBottom);

        if (this.hasBorder)
            offsets.push(this.border.offsetBottom);

        return this.calcMax(offsets);
    }

    get offsetRight() {
        let offsets = []

        if (this.hasBackground)
            offsets.push(this.background.offsetRight);

        if (this.hasCustom)
            offsets.push(this.custom.offsetRight);

        if (this.hasBorder)
            offsets.push(this.border.offsetRight);

        return this.calcMax(offsets);
    }

    get offsetTop() {
        let offsets = []

        if (this.hasBackground)
            offsets.push(this.background.offsetTop);

        if (this.hasCustom)
            offsets.push(this.custom.offsetTop);

        if (this.hasBorder)
            offsets.push(this.border.offsetTop);

        return this.calcMax(offsets);
    }

    get hasBorder() {
        return this.border != null && this.border.has;
    }

    get hasBackground() {
        return this.background != null && this.background.has;
    }

    get hasCustom() {
        return this.custom != null && this.custom.has;
    }

    calcMax(list, prop) {
        let max = 0;

        for (let k = 0; k < list.length; k++) {
            let item = list[k];

            if (prop != null)
                item = item[prop];

            if (item > max)
                max = item;
        }

        return max;
    }



    update(uid, frame, delta, state) {
        if (this.hasBackground)
            this.background.update(uid, frame, delta, state);

        if (this.hasCustom)
            this.custom.update(uid, frame, delta, state);

        if (this.hasBorder)
            this.border.update(uid, frame, delta, state);

        return true;
    }

    draw(display, frame) {
        if (this.hasBackground)
            this.background.draw(display, frame);

        if (this.hasCustom)
            this.custom.draw(display, frame);

        if (this.hasBorder)
            this.border.draw(display, frame);

        /* rect do border */
        // let ctx = display.ctx;
        // ctx.save();
        // ctx.strokeStyle = 'rgb(255,255,255)';
        // ctx.strokeWidth = 2;
        // ctx.lineWidth = 1;
        // ctx.setLineDash([10, 1]);
        // ctx.beginPath();
        // ctx.moveTo(this.begin.x, this.begin.y)
        // ctx.lineTo(this.begin.x + this.width, this.begin.y)
        // ctx.lineTo(this.begin.x + this.width, this.begin.y - this.height)
        // ctx.lineTo(this.begin.x, this.begin.y - this.height)
        // ctx.lineTo(this.begin.x, this.begin.y)
        // ctx.stroke();
        // ctx.restore();
    }
}