import Vector from './vector.js';

export default class InstagramSticker {
    static DEFAULTS = {
        mode: InstagramSticker.MODE__HASHTAG,
        style: InstagramSticker.STYLE__TRANSLUCENT,
        size: 25,
        angle: 0,
        font: "Livetat Modern"
    }

    static CONFIG = {
        pen: null,
        begin: new Vector(0, 0),
        content: "technology",
        url: ""
    }

    static MODE__MENTION = "mention";
    static MODE__HASHTAG = "hash";
    static MODE__LINK = "link";

    static STYLE__SINGLE = "single";
    static STYLE__BLACK = "black";
    static STYLE__INSTA = "insta";
    static STYLE__RAINBOW = "rainbow";
    static STYLE__TRANSLUCENT = "translucent";

    static BRAND__RED = "#FA3253"
    static BRAND__ORANGE = "#FC8320"
    static BRAND__YELLOW = "#FFD600"
    static BRAND__PURPLE = "#DB45CB"
    static BRAND__BLUE = "#7638FA"
    static BRAND__LIGHT_BLUE = "#00A0FB"
    static BRAND__BLACK = "#343537"
    static BRAND__WHITE = "#FEFEFE"

    static RAINBOW__RED = "#F62410"
    static RAINBOW__ORANGE = "#FB5E1A"
    static RAINBOW__YELLOW = "#f4bd10"
    static RAINBOW__GREEN = "#2cd430"
    static RAINBOW__LIGHT_BLUE = "#00A0FB"
    static RAINBOW__BLUE = "#0887FB"
    static RAINBOW__PURPLE = "#6350F2"

    static MAGIC__FONT_OFFSET = 0
    static MAGIC__ICON_OFFSET = 1

    static COLOR__SINGLE_MENTION = {
        textColor: InstagramSticker.BRAND__ORANGE,
        iconColor: InstagramSticker.BRAND__ORANGE,
        backgroundColor: InstagramSticker.BRAND__WHITE
    }

    static COLOR__SINGLE_HASHTAG = {
        textColor: InstagramSticker.BRAND__RED,
        iconColor: InstagramSticker.BRAND__RED,
        backgroundColor: InstagramSticker.BRAND__WHITE
    }

    static COLOR__SINGLE_LINK = {
        textColor: InstagramSticker.BRAND__LIGHT_BLUE,
        iconColor: InstagramSticker.BRAND__LIGHT_BLUE,
        backgroundColor: InstagramSticker.BRAND__WHITE
    }

    static COLOR__BLACK = {
        textColor: InstagramSticker.BRAND__BLACK,
        iconColor: InstagramSticker.BRAND__BLACK,
        backgroundColor: InstagramSticker.BRAND__WHITE
    }

    static COLOR__INSTA = {
        textColor: InstagramSticker.BRAND__BLACK,
        iconColor: 'grad',
        backgroundColor: InstagramSticker.BRAND__WHITE
    }

    static COLOR__RAINBOW = {
        textColor: 'grad',
        iconColor: 'grad',
        backgroundColor: InstagramSticker.BRAND__WHITE
    }

    static COLOR__TRANSLUCENT = {
        textColor: '#FAFAFA',
        iconColor: '#FAFAFA',
        backgroundColor: 'rgba(255,255,255,0.19)'
    }

    static SPECS__MENTION = {
        icon: false,
        symbol: "@",
        height: 1.9,
        between: 0,
        iconWidth: 0,
        iconHeight: 0,
        top: 0.45,
        bottom: 0.45,
        left: 0.5,
        right: 0.475,
        radius: 0.25
    }

    static SPECS__HASHTAG = {
        icon: false,
        symbol: "#",
        height: 1.9,
        between: 0,
        iconWidth: 0,
        iconHeight: 0,
        top: 0.45,
        bottom: 0.45,
        left: 0.475,
        right: 0.525,
        radius: 0.25
    }

    static SPECS__LINK = {
        icon: true,
        symbol: null,
        height: 1.905,
        between: 0.25,
        iconWidth: 1,
        iconHeight: 1,
        top: 0.452,
        bottom: 0.452,
        left: 0.405,
        right: 0.524,
        iconStrokeWidth: 0.12,
        iconBar: 0.275,
        iconInnerRadius: 0.24,
        iconOuterRadius: 0.365,
        iconInnerSpacing: 0.181,
        iconExternalSpacing: 0.171, //era 16px arredondei pra 21
        radius: 0.25
    }




    constructor(config, props) {
        Object.assign(this, InstagramSticker.CONFIG, config)

        this.props = Object.assign({}, InstagramSticker.DEFAULTS)
        this.iconPen = new InstagramLinkIconCanvasPen(config.pen.display)

        this.setProps(props);

        document.fonts.ready.then(function () {

            this.dim();
            this.pos();
        }.bind(this))

        /* config do callback externo para ops que dependem de dimensões */
        if (!!this.after)
            setTimeout(function () {
                this.after(this, this.pen.display);
            }.bind(this), 400);
    }

    dim() {
        let ctx = this.pen.ctx;

        ctx.save();

        ctx.font = "200 " + this.props.size + "px " + this.props.font;
        ctx.fillStyle = this.props.specs.textColor;

        this.textWidth = ctx.measureText(this.content.toUpperCase()).width;
        this.textHeight = this.props.size * 4/5;

        this.symbolWidth = this.props.specs.symbol != null ? ctx.measureText(this.props.specs.symbol.toUpperCase()).width
                                                           : this.props.specs.icon ? this.normalized.iconWidth
                                                                                   : 0
        this.symbolHeight = this.props.specs.symbol != null ? this.textHeight
                                                            : this.props.specs.icon ? this.normalized.iconHeight
                                                                                    : 0

        this.width = this.normalized.left + this.symbolWidth + this.normalized.between + this.textWidth + this.normalized.right
        this.height = this.normalized.height

        ctx.restore();
    }

    pos() {
        this.center = new Vector(this.begin.x + this.width/2, this.begin.y + this.height/2)
        this.textBegin = new Vector(this.begin.x + this.normalized.left + this.symbolWidth + this.normalized.between, this.begin.y + this.normalized.top - InstagramSticker.MAGIC__FONT_OFFSET)
        this.symbolBegin = new Vector(this.begin.x + this.normalized.left, this.begin.y + this.normalized.top - InstagramSticker.MAGIC__FONT_OFFSET)

        if (this.props.style == InstagramSticker.STYLE__RAINBOW) {
            let grad = this.generateRainbowStyleGradient()

            this.props.specs.textColor = grad;
            this.props.specs.iconColor = grad;
        }

        if (this.props.style == InstagramSticker.STYLE__INSTA) {
            let grad = this.props.mode == InstagramSticker.MODE__LINK ? this.generateInstaStyleForLinkGradient() : this.generateInstaStyleGradient()

            this.props.specs.iconColor = grad
        }
    }

    setProps(props) {
        Object.assign(this.props, props)

        this.props.specs = this.specsForMode(this.props.mode, this.props.style);
        this.normalized = this.normalizeSpecs(this.props.specs, this.props.size*4/5);

        this.dim();
        this.pos();
    }

    normalizeSpecs(specs, size) {
        let normalized = {}

        normalized.height = specs.height * size;
        normalized.between = specs.between * size;
        normalized.iconWidth = specs.iconWidth * size;
        normalized.iconHeight = specs.iconHeight * size;
        normalized.top = specs.top * size;
        normalized.left = specs.left * size;
        normalized.right = specs.right * size;
        normalized.bottom = specs.bottom * size;
        normalized.radius = specs.radius * size;

        /* TODO: melhorar o lugar em que isso é persistido */
        if (specs.icon) {
            normalized.iconStrokeWidth = specs.iconStrokeWidth * size;
            normalized.iconBar = specs.iconBar * size;
            normalized.iconInnerRadius = specs.iconInnerRadius * size;
            normalized.iconOuterRadius = specs.iconOuterRadius * size;
            normalized.iconInnerSpacing = specs.iconInnerSpacing * size;
            normalized.iconExternalSpacing = specs.iconExternalSpacing * size;
        }

        return normalized;
    }

    specsForMode(mode, style, size) {
        let specs = {}

        /* specs por modo */
        if (mode == InstagramSticker.MODE__MENTION)
            specs = Object.assign({}, InstagramSticker.SPECS__MENTION)
        else if (mode == InstagramSticker.MODE__HASHTAG)
            specs = Object.assign({}, InstagramSticker.SPECS__HASHTAG)
        else if (mode == InstagramSticker.MODE__LINK)
            specs = Object.assign({}, InstagramSticker.SPECS__LINK)

        /* specs por estilo */
        if (style == InstagramSticker.STYLE__SINGLE) {
            if (mode == InstagramSticker.MODE__MENTION)
                Object.assign(specs, InstagramSticker.COLOR__SINGLE_MENTION)
            else if (mode == InstagramSticker.MODE__HASHTAG)
                Object.assign(specs, InstagramSticker.COLOR__SINGLE_HASHTAG)
            else if (mode == InstagramSticker.MODE__LINK)
                Object.assign(specs, InstagramSticker.COLOR__SINGLE_LINK)
        }
        else if (style == InstagramSticker.STYLE__BLACK)
            Object.assign(specs, InstagramSticker.COLOR__BLACK)
        else if (style == InstagramSticker.STYLE__RAINBOW)
            Object.assign(specs, InstagramSticker.COLOR__RAINBOW)
        else if (style == InstagramSticker.STYLE__TRANSLUCENT)
            Object.assign(specs, InstagramSticker.COLOR__TRANSLUCENT)
        else if (style == InstagramSticker.STYLE__INSTA)
            Object.assign(specs, InstagramSticker.COLOR__INSTA)

        return specs;
    }

    generateRainbowStyleGradient() {
        let grad = this.pen.ctx.createLinearGradient(this.symbolBegin.x, this.begin.y, this.symbolBegin.x + this.symbolWidth + this.textWidth, this.begin.y)

        grad.addColorStop(0/8, InstagramSticker.RAINBOW__RED)
        grad.addColorStop(1/8, InstagramSticker.RAINBOW__ORANGE)
        grad.addColorStop(3.3/8, InstagramSticker.RAINBOW__YELLOW)
        grad.addColorStop(4.5/8, InstagramSticker.RAINBOW__GREEN)
        grad.addColorStop(6/8, InstagramSticker.RAINBOW__LIGHT_BLUE)
        grad.addColorStop(7/8, InstagramSticker.RAINBOW__BLUE)
        grad.addColorStop(7.8/8, InstagramSticker.RAINBOW__PURPLE)

        return grad;
    }

    generateInstaStyleGradient() {
        let grad = this.pen.ctx.createLinearGradient(
            this.symbolBegin.x, this.symbolBegin.y,
            this.symbolBegin.x + this.symbolWidth, this.symbolBegin.y + this.symbolHeight
        )

        grad.addColorStop(0.25, InstagramSticker.BRAND__ORANGE)
        grad.addColorStop(0.5, InstagramSticker.BRAND__RED)
        grad.addColorStop(0.75, InstagramSticker.BRAND__PURPLE)

        return grad;
    }

    generateInstaStyleForLinkGradient() {
        let grad = this.pen.ctx.createLinearGradient(
            -this.symbolWidth/2, 0,
            this.symbolWidth/2, 0
        )

        grad.addColorStop(0.25, InstagramSticker.BRAND__ORANGE)
        grad.addColorStop(0.4, InstagramSticker.BRAND__RED)
        grad.addColorStop(0.6, InstagramSticker.BRAND__PURPLE)

        return grad;
    }


    updateBegin(x, y) {
        if (x != null)
            this.begin.x = x;

        if (y != null)
            this.begin.y = y;

        this.pos()
    }

    getBegin() {
        return this.begin.dup;
    }

    onEvent(event, data) {
        if (event === 'click' && this.isInside(data)) {
            if (!!this.url)
                window.open(this.url);
        }

        if (event === 'mousemove' && this.isInside(data)) {
            this.pen.display.canvas.style.cursor = 'pointer';
        }
    }

    getRect() {
        return {
            tl: new Vector(this.begin.x, this.begin.y),
            tr: new Vector(this.begin.x + this.width, this.begin.y),
            bl: new Vector(this.begin.x, this.begin.y + this.height),
            br: new Vector(this.begin.x + this.width, this.begin.y + this.height)
        }
    }

    isInside(v) {
        let r = this.getRect();

        let insideX = v.x > r.tl.x && v.x < r.tr.x
        let insideY = v.y > r.tl.y && v.y < r.bl.y

        return insideX && insideY;
    }

    update(uid, frame, delta, state) {
        // this.dim();
        // this.pos();
        return true;
    }

    draw(display, frame) {
        this.pen.draw(this, frame);
        this.iconPen.draw(this, frame);
    }
}

export class InstagramStickerCanvasPen {
    constructor(display) {
        this.display = display;
        this.ctx = display.ctx;

        // friday man i dont really care right now
        CanvasRenderingContext2D.prototype.roundRect = function (x, y, w, h, r) {
            if (w < 2 * r) r = w / 2;
            if (h < 2 * r) r = h / 2;
            this.beginPath();
            this.moveTo(x+r, y);
            this.arcTo(x+w, y,   x+w, y+h, r);
            this.arcTo(x+w, y+h, x,   y+h, r);
            this.arcTo(x,   y+h, x,   y,   r);
            this.arcTo(x,   y,   x+w, y,   r);
            this.closePath();
            return this;
        }
    }

    draw(element, frame) {
        this.ctx.save()
        this.ctx.fillStyle = element.props.specs.backgroundColor;
        this.ctx.beginPath();
        // if (!!this.ctx.roundRect) {
            this.ctx.roundRect(element.begin.x, element.begin.y + (isMobile.iOS() ? 8 : 0), element.width, element.normalized.top + element.textHeight + element.normalized.bottom, element.normalized.radius)
            this.ctx.fill();
        // } else {
        //     this.ctx.fillRect(element.begin.x, element.begin.y, element.width, element.normalized.top + element.textHeight + element.normalized.bottom)
        // }


        this.ctx.restore();

        if (element.props.specs.symbol != null) {
            this.ctx.save();
            this.ctx.fillStyle = element.props.specs.iconColor;
            this.ctx.textBaseline = 'top';
            this.ctx.font = "200 " + element.props.size + "px " + element.props.font;
            this.ctx.fillText(element.props.specs.symbol.toUpperCase(), element.symbolBegin.x, element.symbolBegin.y);

            this.ctx.restore();
        }

        this.ctx.save();
        this.ctx.fillStyle = element.props.specs.textColor;
        this.ctx.font = "200 " + element.props.size + "px " + element.props.font;
        this.ctx.textBaseline = 'top';
        this.ctx.fillText(element.content.toUpperCase(), element.textBegin.x, element.textBegin.y);
        this.ctx.restore();

        /* rect */
        // let ref = element.begin;
        // this.ctx.save();
        // this.ctx.strokeStyle = 'rgb(250,45,137)';
        // this.ctx.lineWidth = 1;
        // this.ctx.beginPath();
        // this.ctx.moveTo(ref.x, ref.y)
        // this.ctx.lineTo(ref.x + element.width, ref.y)
        // this.ctx.lineTo(ref.x + element.width, ref.y + element.height)
        // this.ctx.lineTo(ref.x, ref.y + element.height)
        // this.ctx.lineTo(ref.x, ref.y)
        // this.ctx.stroke();
        // this.ctx.restore();
    }
}

export class InstagramLinkIconCanvasPen {
    constructor(display) {
        this.display = display;
        this.ctx = display.ctx;
    }

    draw(element, frame) {
        let specs = element.normalized;
        let b = element.symbolBegin;
        let w = specs.iconWidth;
        let h = specs.iconHeight;
        let l = specs.iconStrokeWidth;
        let c = new Vector(element.symbolBegin.x + specs.iconWidth/2, element.symbolBegin.y + (isMobile.iOS() ? 6 : 0) + specs.iconHeight/2 + InstagramSticker.MAGIC__ICON_OFFSET)

        this.ctx.save();
        this.ctx.translate(c.x, c.y);
        this.ctx.rotate(Math.PI/4)


        /* linha de guia */
        this.ctx.lineWidth = 1;
        // this.ctx.strokeStyle = InstagramSticker.BRAND__LIGHT_BLUE;
        // this.ctx.fillStyle = element.props.specs.iconColor;
        // this.ctx.fillRect(-w/2, -h/2, w, h);


        /* linha central */
        this.ctx.lineWidth = l;
        this.ctx.lineCap = 'round';
        this.ctx.fillStyle = element.props.specs.iconColor;
        this.ctx.strokeStyle = element.props.specs.iconColor;

        this.ctx.beginPath();
        this.ctx.moveTo(0, -specs.iconBar/2 - l/8  )
        this.ctx.lineTo(0, +specs.iconBar/2 + l/8 )
        this.ctx.stroke();
        this.ctx.closePath();


        /* linha externa superior */
        let bu = new Vector(-specs.iconInnerRadius , -specs.iconExternalSpacing + l/4)
        let eu = new Vector(specs.iconInnerRadius , -specs.iconExternalSpacing + l/4)
        let cbu = new Vector(-specs.iconInnerRadius  , - specs.iconOuterRadius - l*5/4)
        let ceu = new Vector(specs.iconInnerRadius , - specs.iconOuterRadius - l*5/4)

        this.ctx.beginPath();
        this.ctx.moveTo(bu.x, bu.y)
        this.ctx.bezierCurveTo(cbu.x, cbu.y, ceu.x, ceu.y, eu.x, eu.y)
        this.ctx.stroke();
        this.ctx.closePath();


        /* linha externa inferior */
        let bd = new Vector(-specs.iconInnerRadius, specs.iconExternalSpacing - l/4)
        let ed = new Vector(specs.iconInnerRadius , specs.iconExternalSpacing - l/4)
        let cbd = new Vector(-specs.iconInnerRadius  , specs.iconOuterRadius + l*5/4)
        let ced = new Vector(specs.iconInnerRadius , specs.iconOuterRadius + l*5/4)

        this.ctx.beginPath();
        this.ctx.moveTo(bd.x, bd.y)
        this.ctx.bezierCurveTo(cbd.x, cbd.y, ced.x, ced.y, ed.x, ed.y)
        this.ctx.stroke();
        this.ctx.closePath();


        this.ctx.restore();

        // iconDiagonal: 1,
        // iconBar: 0.5,
        // iconInnerRadius: 0.25,
        // iconOuterRadius: 0.404,
        // iconInnerSpacing: 0.25,
        // iconExternalSpacing: 0.5, //era 16px arredondei pra 21
    }
}





