import Vector from './vector.js';

export default class Line {
    constructor(config) {
        Object.assign(
            this,
            {
                pen: null,
                type: 'line',
                begin: new Vector(0, 0),
                alignY: true,
                id: 1,
                width: 0,
                height: 0,
                maxWidth: 320,
                words: []
            },
            config
        )

        this.init();
    }

    init() {
        //this.dim();

        //this.pos();
    }

    dim(recursive) {
        if (this.words.length == 0)
            return;

        if (!!recursive)
            this.words.forEach(function(word) {
                word.dim();
            });

        this.width = this.calcTotalWidth();
        this.height = this.findBiggerWordHeight();
    }

    /* TODO: tem o begin alterado por fora e dai atualiza o begin do word, o word se atualiza quando chama o updateBegin */
    pos() {
        if (this.words.length == 0)
            return;

        let cursor = 0;

        this.words.forEach(function(w, k) {
            let s = k != 0 ? w.spacing : 0;
            let bx = this.begin.x + cursor + s;
            let by = this.begin.y;

            if (this.alignY && w.height < this.height)
                by -= (this.height - w.height) / 2;

            w.updateBegin(bx, by);

            cursor += w.width + s;
        }.bind(this));
    }

    calcTotalWidth() {
        let width = 0;

        this.words.forEach(function(w, k) {
            width += w.width + (k != 0 ? w.spacing : 0);
        });

        return width;
    }

    findBiggerWordHeight() {
        let max = 0;

        this.words.forEach(function(w) {
            if (w.height > max)
                max = w.height;
        });

        return max;
    }



    push(word) {
        if (this.width + word.width > this.maxWidth)
            return false;

        this.words.push(word);

        word.line = this.id;

        this.dim();

        return true;
    }



    /* TODO: repassar o false do update das childs para o estado */
    update(uid, frame, delta, state) {
        this.words.forEach(function(w) {
            w.update(uid, frame, delta, state);
        });

        return true;
    }

    draw(display, frame) {
        this.pen.draw(this, frame);
    }
}

export class LineCanvasPen {
    constructor(display) {
        this.display = display;
        this.ctx = display.ctx;
    }

    draw(element, frame) {
        for (let k = 0; k < element.words.length; k++)
            element.words[k].draw(this.display, frame);

        /* rect da line */
        // this.ctx.save();
        // this.ctx.strokeStyle = 'rgba(255,255,255,0.7)';
        // this.ctx.strokeWidth = 1;
        // this.ctx.lineWidth = 1;
        // this.ctx.setLineDash([5, 15]);
        // this.ctx.beginPath();
        // this.ctx.moveTo(element.begin.x, element.begin.y)
        // this.ctx.lineTo(element.begin.x + element.width, element.begin.y)
        // this.ctx.lineTo(element.begin.x + element.width, element.begin.y - element.height)
        // this.ctx.lineTo(element.begin.x, element.begin.y - element.height)
        // this.ctx.lineTo(element.begin.x, element.begin.y)
        // this.ctx.stroke();
        // this.ctx.restore();
    }
}