import Vector from "./vector";

export default class WordBorder {
    static DEFAULTS = {
        color: 'transparent',
        strokeWidth: 0,
        lineCap: 'round',
        angle: 0,
        concavity: null,
        padding: 0,
        margin: 0,
        shadow: false,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowBlur: 0,
        shadowColor: 'rgba(255,255,255,0.7)'
    }

    static CONCAVITY_DEFAULTS = {
        cbx: 0.25,
        cex: 0.25,
        cby: 1,
        cey: 1,
        ratio: 0.1
    }

    constructor(word, config) {
        this.word = word;
        this.begin = word.getBegin().dup;
        this.animator = null;

        this.setProps(config);
    }

    init() {
        this.dim();
    }

    dim() {
        this.enabled.forEach(function(position) {
            this.prepareConcavityProps(this.props[position]);
        }.bind(this));
    }

    setProps(conf) {
        this.props = {}
        this.enabled = []
        this.animator = {}

        if (conf == null)
            return;

        if (!!conf.enabled)
            this.enabled = conf.enabled

        this.enabled.forEach(function (position) {
            let all = conf.props.all
            let prop = conf.props[position]

            if (typeof(all) == 'function') {
                this.animator.all = all;

                all = this.animator.all(this.word.pen.display, 0);
            }

            if (typeof(prop) == 'function') {
                this.animator[position] = prop;

                prop = this.animator[position](this.word.pen.display, 0)
            }

            this.props[position] = Object.assign(
                {},
                WordBorder.DEFAULTS,
                !!all ? all : {},
                !!prop ? prop : {}
            )
        }.bind(this));

        this.dim();
    }

    updateProps(display, frame) {
        let changed = false;

        this.enabled.forEach(function(position) {
            if (this.animator.all != null) {
                Object.assign(this.props[position], this.animator.all(display, frame))

                changed = true
            }

            if (this.animator[position] != null) {
                Object.assign(this.props[position], this.animator[position](display, frame))

                changed = true
            }
        }.bind(this));

        if (changed)
            this.dim();
    }

    updateBegin(x, y) {
        if (x != null)
            this.begin.x = x - this.offsetLeft;

        if (y != null)
            this.begin.y = y + this.offsetBottom;
    }

    getBegin() {
        return this.begin.dup;
    }

    get has() {
        return this.enabled.length !== 0;
    }

    get hasLeft() {
        return this.has && !!this.props.left;
    }

    get hasRight() {
        return this.has && !!this.props.right;
    }

    get hasBottom() {
        return this.has && !!this.props.bottom;
    }

    get hasTop() {
        return this.has && !!this.props.top;
    }

    get offsetLeft() {
        if (!this.props.left)
            return 0;

        return this.props.left.padding + this.props.left.margin + this.internalOffsetLeft;
    }

    get internalOffsetLeft() {
        if (!this.props.left) {
            return 0;
        }

        if (!this.props.left.concavity)
            return this.props.left.strokeWidth/2;

        let cfg = this.props.left.concavity;

        let val = cfg.ratio;

        if (cfg.cby*cfg.ratio > val)
            val = cfg.cby*cfg.ratio;

        if (cfg.cey*cfg.ratio > val)
            val = cfg.cey*cfg.ratio;

        val *= this.height;
        val -= this.props.left.strokeWidth/2;

        return val;
    }

    get internalOffsetRight() {
        if (!this.props.right) {
            return 0;
        }

        if (!this.props.right.concavity)
            return this.props.right.strokeWidth/2;

        let cfg = this.props.right.concavity;

        let val = cfg.ratio;

        if (cfg.cby*cfg.ratio > val)
            val = cfg.cby*cfg.ratio;

        if (cfg.cey*cfg.ratio > val)
            val = cfg.cey*cfg.ratio;

        val *= this.height;
        val -= this.props.right.strokeWidth/2;

        return val;
    }

    get offsetRight() {
        if (!this.props.right)
            return 0;

        return this.props.right.padding + this.props.right.margin + this.internalOffsetRight;
    }

    get offsetTop() {
        if (!this.props.top)
            return 0;

        return this.props.top.padding + this.props.top.margin + this.props.top.strokeWidth;
    }

    get offsetBottom() {
        if (!this.props.bottom)
            return 0;

        return this.props.bottom.padding + this.props.bottom.margin + this.props.bottom.strokeWidth;
    }

    get width() {
        return this.word.textWidth + this.offsetLeft + this.offsetRight
    }

    get height() {
        return this.word.textHeight + this.offsetTop + this.offsetBottom
    }

    prepareConcavityProps(props) {
        if (!props.concavity)
            return;

        let c = props.concavity

        if (typeof(c) == 'number') {
            props.concavity = Object.assign({}, WordBorder.CONCAVITY_DEFAULTS, { ratio: c })
        } else {
            props.concavity = Object.assign({}, WordBorder.CONCAVITY_DEFAULTS, props.concavity)
        }
    }



    update(uid, frame, delta, state) {
        this.updateProps(state.display, frame);

        return true;
    }

    draw(display, frame) {
        let ctx = this.word.pen.ctx;

        if (this.hasTop) {
            ctx.save();
            ctx.strokeStyle = this.props.top.color;
            ctx.strokeWidth = this.props.top.strokeWidth;
            ctx.lineWidth = this.props.top.strokeWidth;
            ctx.lineCap = this.props.top.lineCap;

            if (this.props.top.shadow) {
                ctx.shadowOffsetX = this.props.top.shadowOffsetX;
                ctx.shadowOffsetY = this.props.top.shadowOffsetY;
                ctx.shadowBlur = this.props.top.shadowBlur;
                ctx.shadowColor = this.props.top.shadowColor;
            }

            if (!!this.props.top.lineDash)
                ctx.setLineDash(this.props.top.lineDash);

            ctx.translate(this.begin.x + this.width / 2, this.begin.y - this.height)
            ctx.rotate(-this.props.top.angle * (Math.PI / 180));

            ctx.beginPath();
            ctx.moveTo(-this.width / 2 + this.internalOffsetLeft, 0)

            if (!this.props.top.concavity) {
                ctx.lineTo(this.width / 2 - this.internalOffsetRight, 0)
                ctx.stroke();
                ctx.restore();
            } else {
                let cfg = this.props.top.concavity;

                let width = this.width - this.internalOffsetRight - this.internalOffsetLeft;
                let height = width*cfg.ratio;

                let b = new Vector(-this.width / 2 + this.internalOffsetLeft, 0);
                let e = new Vector(this.width / 2 - this.internalOffsetRight, 0);
                let cb = new Vector(b.x + width*cfg.cbx, b.y - height*cfg.cby);
                let ce = new Vector(b.x + (1 - cfg.cex)*width, b.y - height*cfg.cey);

                ctx.bezierCurveTo(cb.x, cb.y, ce.x, ce.y, e.x, e.y);
                ctx.stroke();
                ctx.restore();

                ctx.restore();
            }
        }

        if (this.hasLeft) {
            ctx.save();
            ctx.strokeStyle = this.props.left.color;
            ctx.strokeWidth = this.props.left.strokeWidth;
            ctx.lineWidth = this.props.left.strokeWidth;
            ctx.lineCap = this.props.left.lineCap;

            if (this.props.left.shadow) {
                ctx.shadowOffsetX = this.props.left.shadowOffsetX;
                ctx.shadowOffsetY = this.props.left.shadowOffsetY;
                ctx.shadowBlur = this.props.left.shadowBlur;
                ctx.shadowColor = this.props.left.shadowColor;
            }

            if (!!this.props.left.lineDash)
                ctx.setLineDash(this.props.left.lineDash);

            ctx.translate(this.begin.x + this.internalOffsetLeft, this.begin.y - this.height / 2)
            ctx.rotate(-this.props.left.angle * (Math.PI / 180));

            ctx.beginPath();
            ctx.moveTo(0, -this.height / 2)

            if (!this.props.left.concavity) {
                ctx.lineTo(0, this.height / 2)
                ctx.stroke();
                ctx.restore();
            } else {
                let cfg = this.props.left.concavity;

                let width = this.height;
                let height = width*cfg.ratio;

                let b = new Vector(0, -this.height / 2);
                let e = new Vector(0, this.height / 2);
                let cb = new Vector(b.x - height*cfg.cby, b.y + width*cfg.cbx);
                let ce = new Vector(b.x - height*cfg.cey, b.y + (1 - cfg.cex)*width);

                ctx.bezierCurveTo(cb.x, cb.y, ce.x, ce.y, e.x, e.y);
                ctx.stroke();
                ctx.restore();

                /* rect do bezier */
                // let ref = b;
                // ctx.save();
                // ctx.translate(this.begin.x, this.begin.y - this.height/2)
                // ctx.rotate(-this.props.left.angle * (Math.PI / 180));
                // ctx.strokeStyle = 'rgba(255,0,0,0.6)';
                // ctx.strokeWidth = 1;
                // ctx.lineWidth = 1;
                // ctx.setLineDash([5, 10]);
                // ctx.beginPath();
                // ctx.moveTo(0, -this.height / 2)
                // ctx.lineTo(0, this.height/2)
                // ctx.lineTo( -height, this.height/2)
                // ctx.lineTo( -height, -this.height/2)
                // ctx.lineTo(ref.x, ref.y)
                // ctx.stroke();
                // ctx.restore();
                //
                // ctx.save();
                // ctx.translate(this.begin.x, this.begin.y - this.height/2)
                // ctx.rotate(-this.props.left.angle * (Math.PI / 180));
                // ctx.strokeWidth = 2;
                // ctx.lineWidth = 2;
                // ctx.setLineDash([5, 5]);
                // ctx.beginPath();
                // ctx.moveTo(0, -this.height / 2)
                // ctx.strokeStyle = 'rgb(45,175,250)';
                // ctx.lineTo(cb.x, cb.y);
                // ctx.stroke();
                // ctx.fillRect(cb.x - 2, cb.y - 2, 4, 4);
                // ctx.restore();
                //
                // ctx.save();
                // ctx.translate(this.begin.x, this.begin.y - this.height/2)
                // ctx.rotate(-this.props.left.angle * (Math.PI / 180));
                // ctx.strokeWidth = 2;
                // ctx.lineWidth = 2;
                // ctx.setLineDash([5, 5]);
                // ctx.beginPath();
                // ctx.moveTo(0, this.height / 2)
                // ctx.strokeStyle = 'rgb(153,255,0)';
                // ctx.lineTo(ce.x, ce.y);
                // ctx.stroke();
                // ctx.fillRect(ce.x - 2, ce.y - 2, 4, 4);
                // ctx.restore();
            }
        }

        if (this.hasRight) {
            ctx.save();
            ctx.strokeStyle = this.props.right.color;
            ctx.strokeWidth = this.props.right.strokeWidth;
            ctx.lineWidth = this.props.right.strokeWidth;
            ctx.lineCap = this.props.right.lineCap;

            if (this.props.right.shadow) {
                ctx.shadowOffsetX = this.props.right.shadowOffsetX;
                ctx.shadowOffsetY = this.props.right.shadowOffsetY;
                ctx.shadowBlur = this.props.right.shadowBlur;
                ctx.shadowColor = this.props.right.shadowColor;
            }

            if (!!this.props.right.lineDash)
                ctx.setLineDash(this.props.right.lineDash);

            ctx.translate(this.begin.x + this.width - this.internalOffsetRight, this.begin.y - this.height / 2)
            ctx.rotate(-this.props.right.angle * (Math.PI / 180));

            ctx.beginPath();
            ctx.moveTo(0, -this.height / 2)

            if (!this.props.right.concavity) {
                ctx.lineTo(0, this.height / 2)
                ctx.stroke();
                ctx.restore();
            } else {
                let cfg = this.props.right.concavity;

                let width = this.height;
                let height = width*cfg.ratio;

                let b = new Vector(0, -this.height / 2);
                let e = new Vector(0, this.height / 2);
                let cb = new Vector(b.x + height*cfg.cby, b.y + width*cfg.cbx);
                let ce = new Vector(b.x + height*cfg.cey, b.y + (1 - cfg.cex)*width);

                ctx.bezierCurveTo(cb.x, cb.y, ce.x, ce.y, e.x, e.y);
                ctx.stroke();
                ctx.restore();
            }
        }

        if (this.hasBottom) {
            ctx.save();
            ctx.strokeStyle = this.props.bottom.color;
            ctx.strokeWidth = this.props.bottom.strokeWidth;
            ctx.lineWidth = this.props.bottom.strokeWidth;
            ctx.lineCap = this.props.bottom.lineCap;

            if (this.props.bottom.shadow) {
                ctx.shadowOffsetX = this.props.bottom.shadowOffsetX;
                ctx.shadowOffsetY = this.props.bottom.shadowOffsetY;
                ctx.shadowBlur = this.props.bottom.shadowBlur;
                ctx.shadowColor = this.props.bottom.shadowColor;
            }

            if (!!this.props.bottom.lineDash)
                ctx.setLineDash(this.props.bottom.lineDash);

            ctx.translate(this.begin.x + this.width / 2, this.begin.y)
            ctx.rotate(-this.props.bottom.angle * (Math.PI / 180));

            ctx.beginPath();
            ctx.moveTo(-this.width / 2 + this.internalOffsetLeft, 0)

            if (!this.props.bottom.concavity) {
                ctx.lineTo(this.width / 2 - this.internalOffsetRight, 0)
                ctx.stroke();
                ctx.restore();
            } else {
                let cfg = this.props.bottom.concavity;

                let width = this.width - this.internalOffsetLeft - this.internalOffsetRight;
                let height = width*cfg.ratio;

                let b = new Vector(-this.width / 2 + this.internalOffsetLeft, 0);
                let e = new Vector(this.width / 2 - this.internalOffsetRight, 0);
                let cb = new Vector(b.x + width*cfg.cbx, b.y + height*cfg.cby);
                let ce = new Vector(b.x + (1 - cfg.cex)*width, b.y + height*cfg.cey);

                ctx.bezierCurveTo(cb.x, cb.y, ce.x, ce.y, e.x, e.y);
                ctx.stroke();
                ctx.restore();

                /* rect do bezier */
                // let ref = b;
                // ctx.save();
                // ctx.translate(this.begin.x + this.width / 2, this.begin.y)
                // ctx.rotate(-this.props.bottom.angle * (Math.PI / 180));
                // ctx.strokeStyle = 'rgba(255,0,0,0.6)';
                // ctx.strokeWidth = 1;
                // ctx.lineWidth = 1;
                // ctx.setLineDash([5, 10]);
                // ctx.beginPath();
                // ctx.moveTo(-this.width / 2, 0)
                // ctx.lineTo(ref.x + width, ref.y)
                // ctx.lineTo(ref.x + width, ref.y + height)
                // ctx.lineTo(ref.x, ref.y + height)
                // ctx.lineTo(ref.x, ref.y)
                // ctx.stroke();
                // ctx.restore();
                //
                // ctx.save();
                // ctx.translate(this.begin.x + this.width / 2, this.begin.y)
                // ctx.rotate(-this.props.bottom.angle * (Math.PI / 180));
                // ctx.strokeWidth = 2;
                // ctx.lineWidth = 2;
                // ctx.setLineDash([5, 5]);
                // ctx.beginPath();
                // ctx.moveTo(-this.width / 2, 0);
                // ctx.strokeStyle = 'rgb(45,175,250)';
                // ctx.lineTo(cb.x, cb.y);
                // ctx.stroke();
                // ctx.fillRect(cb.x - 2, cb.y - 2, 4, 4);
                // ctx.restore();
                //
                // ctx.save();
                // ctx.translate(this.begin.x + this.width / 2, this.begin.y)
                // ctx.rotate(-this.props.bottom.angle * (Math.PI / 180));
                // ctx.strokeWidth = 2;
                // ctx.lineWidth = 2;
                // ctx.setLineDash([5, 5]);
                // ctx.beginPath();
                // ctx.moveTo(this.width / 2, 0);
                // ctx.strokeStyle = 'rgb(153,255,0)';
                // ctx.lineTo(ce.x, ce.y);
                // ctx.stroke();
                // ctx.fillRect(ce.x - 2, ce.y - 2, 4, 4);
                // ctx.restore();
            }
        }

        /* rect */
        // ctx.save();
        // ctx.strokeStyle = 'rgb(45,175,250)';
        // ctx.strokeWidth = 5;
        // ctx.lineWidth = 1;
        // ctx.setLineDash([5, 10]);
        // ctx.beginPath();
        // ctx.moveTo(this.begin.x, this.begin.y)
        // ctx.lineTo(this.begin.x + this.width, this.begin.y)
        // ctx.lineTo(this.begin.x + this.width, this.begin.y - this.height)
        // ctx.lineTo(this.begin.x, this.begin.y - this.height)
        // ctx.lineTo(this.begin.x, this.begin.y)
        // ctx.stroke();
        // ctx.restore();
    }
}