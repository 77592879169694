import Vector from './vector.js';
import Bezi, {BeziCanvasPen} from "./bezi";
import Word, {WordCanvasPen} from "./word";
import PKSymbol from "./pk_symbol";
export default class PKText {
    static STYLE__LIGHT = "light"
    static STYLE__DARK = "dark"
    static STYLE__MONO = "mono"
    static STYLE__NEON = "neon"

    static LIGHT__PARAMS = {
        fill: true,
        color: '#172014'
    }

    static DARK__PARAMS = {
        fill: true,
        color: '#eeeeee'
    }

    static MONO__PARAMS = {
        fill: true,
        color: '#aaaaaa'
    }

    static NEON__PARAMS = {
        fill: true,
        weight: 500,
        color: '#fff',
        stroke: false,
        strokeWidthRatio: 0.02,
        strokeColor: 'rgb(255,255,255)',
        shadow: true,
        shadowColor: 'rgb(255,255,255,0.7)',
        shadowBlurRatio: 0.03,
        // accentWidthRatio: 0.05,
        accentPadding: 0,
        accentColor: '#00bcf5',
        accentShadow: true,
        accentShadowColor: '#3777ff',
        accentShadowBlurRatio: 0.2,
    }

    static CONFIG = {
        pen: null,
        content: "polemk",
        begin: new Vector(-500, -500),
        height: 40,
        debugRect: false,
        style: PKText.STYLE__NEON
    }

    static DEFAULTS = {
        font: "Livetat SOF",
        weight: 500,
        letterSpacing: 0,

        stroke: false,
        strokeWidth: 2,
        strokeWidthRatio: 0.015,
        strokeColor: 'rgb(255,255,255)',

        fill: true,
        color: 'rgb(255,255,255)',

        shadow: false,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowBlur: 0,
        shadowBlurRatio: 0,
        shadowColor: 'rgba(255,255,255,0.7)',

        accent: true,
        accentWidth: 8,
        accentWidthRatio: 0.1,
        accentShadow: false,
        accentShadowColor: 'white',
        accentShadowBlurRatio: 0,
        accentColor: '#00bcf5',
        accentPadding: 0,
        concavity: {
            cbx: 0.3,
            cex: 0.3,
            cby: 0.3,
            cey: 0.3,
            ratio: 1
        }
    }

    constructor(config, props) {
        Object.assign(this, PKText.CONFIG, config)

        this.props = Object.assign({}, PKText.DEFAULTS)

        this.init();

        this.setProps(props);

        document.fonts.ready.then(function () {
            this.dim();
        }.bind(this))
    }

    // TODO: do jeito que tá só cria quando inicializa e depende de dimensionamento
    init() {
        this.outside = new Vector(-5000, -5000);

        this.word = new Word({
                pen: new WordCanvasPen(this.pen.display),
                begin: this.outside.dup,
                content: "polemk"
            }, {
                weight: this.props.weight,
                size: this.height,
                color: this.props.color
            }
        );

        this.bezi = new Bezi({
                pen: new BeziCanvasPen(this.pen.display),
                begin: this.outside.dup,
                angle: 0,
                length: this.height,
                after: function(e, d) {}
            }, function (display, frame) {
                return {
                    strokeWidth: this.props.accentWidth,
                    strokeColor: this.props.accentColor,
                    shadow: this.props.accentShadow,
                    shadowColor: this.props.accentShadowColor,
                    shadowBlur: this.props.accentShadowBlurRatio,
                    concavity: this.props.concavity
                }
            }.bind(this)
        );
    }

    // revisar pq ele tá fazendo um pedaço do setprops
    dim() {
        this.size = 0.92 * this.height;
        this.offset = 0.666 * this.height;
        this.wordCorrection = 0.05 * this.height;

        let wordProps = {
            size: this.size,
            color: this.props.color,
            strokeWidth: this.props.strokeWidthRatio * this.height,
            strokeColor: this.props.strokeColor,
            shadowColor: this.props.shadowColor,
            shadowBlur: this.props.shadowBlurRatio * this.height,
            fill: this.props.fill,
            stroke: this.props.stroke,
            shadow: this.props.shadow
        }

        if (this.style === PKText.STYLE__NEON) {
            let textGrad = this.pen.ctx.createLinearGradient(
                this.begin.x - this.wordCorrection,
                this.begin.y + this.offset,
                this.begin.x + this.word.width,
                this.begin.y + this.offset
            )

            this.fillNeonGradient(textGrad)

            wordProps.strokeColor = textGrad;
        }

        this.word.setProps(wordProps);
        this.word.updateBegin(this.begin.x - this.wordCorrection, this.begin.y + this.offset);

        this.beziWidth = this.props.accentWidthRatio * this.height;
        this.beziOffset = 1.03 * this.word.measure("pol");
        this.beziLength = 0.70 * this.word.measure("e");
        this.beziBegin = new Vector(
            this.begin.x + this.beziOffset,
            this.begin.y + this.beziWidth - this.props.accentPadding
        )

        let grad = this.pen.ctx.createLinearGradient(
            this.beziBegin.x,
            this.beziBegin.y,
            this.beziBegin.x + this.beziLength,
            this.begin.y
        )

        this.fillAccentGradient(grad);

        let beziProps = {
            strokeWidth: this.beziWidth,
            strokeColor: grad,
            concavity: this.props.concavity
        };

        if (this.style === PKText.STYLE__NEON) {
            Object.assign(beziProps, {
                strokeColor: this.props.accentColor,
                shadow: this.props.accentShadow,
                shadowColor: this.props.accentShadowColor,
                shadowBlur: this.props.accentShadowBlurRatio * this.height,
            })
        }

        this.bezi.length = this.beziLength;
        this.bezi.setProps(beziProps);
        this.bezi.updateBegin(this.beziBegin.x, this.beziBegin.y);
    }

    fillNeonGradient(grad) {
        grad.addColorStop(0.35, PKSymbol.DEFAULTS.bezis.alpha.strokeColor)
        grad.addColorStop(0.36, PKSymbol.DEFAULTS.bezis.beta.strokeColor)
        grad.addColorStop(0.57, PKSymbol.DEFAULTS.bezis.beta.strokeColor)
        grad.addColorStop(0.58, PKSymbol.DEFAULTS.bezis.gama.strokeColor)
        grad.addColorStop(0.84, PKSymbol.DEFAULTS.bezis.gama.strokeColor)
        grad.addColorStop(0.85, PKSymbol.DEFAULTS.bezis.delta.strokeColor)
        grad.addColorStop(0.9, PKSymbol.DEFAULTS.bezis.delta.strokeColor)
        grad.addColorStop(1, PKSymbol.DEFAULTS.bezis.delta.strokeColor)

        return grad;
    }

    fillAccentGradient(grad) {
        grad.addColorStop(0.2, PKSymbol.DEFAULTS.bezis.alpha.strokeColor)
        grad.addColorStop(0.21, PKSymbol.DEFAULTS.bezis.beta.strokeColor)
        grad.addColorStop(0.5, PKSymbol.DEFAULTS.bezis.beta.strokeColor)
        grad.addColorStop(0.51, PKSymbol.DEFAULTS.bezis.gama.strokeColor)
        grad.addColorStop(0.8, PKSymbol.DEFAULTS.bezis.gama.strokeColor)
        grad.addColorStop(0.81, PKSymbol.DEFAULTS.bezis.delta.strokeColor)
        grad.addColorStop(1, PKSymbol.DEFAULTS.bezis.delta.strokeColor)

        return grad;
    }


    // TODO: revisar como propagar mudanças de props externa
    setProps(props) {
        let styleProps = this.getStyleProps(this.style);

        Object.assign(this.props, styleProps);

        if (typeof(props) == 'function') {
            this.animator = props;

            Object.assign(this.props, this.animator(this.pen.display, 0))
        } else {
            this.animator = null;

            Object.assign(this.props, props)
        }

        this.dim();
    }

    setStyle(style) {
        if (style === this.style)
            return;

        this.style = style;

        this.setProps(this.props);
    }

    // TODO: como repassar props que vem de function
    updateProps(display, frame) {
        if (this.animator != null) {
            Object.assign(this.props, this.animator(display, frame))

            this.dim();
        }
    }

    updateBegin(x, y) {
        if (x != null)
            this.begin.x = x;

        if (y != null)
            this.begin.y = y;

        this.init()

        this.dim()
    }

    getStyleProps(style) {
        let props = {}

        if (style === PKText.STYLE__DARK)
            props = PKText.DARK__PARAMS

        if (style === PKText.STYLE__LIGHT)
            props = PKText.LIGHT__PARAMS

        if (style === PKText.STYLE__MONO)
            props = PKText.MONO__PARAMS

        if (style === PKText.STYLE__NEON)
            props = PKText.NEON__PARAMS

        return props
    }

    getBegin() {
        return this.begin.dup;
    }

    get width() {
        return this.word.width - this.wordCorrection
    }

    get center() {
        let c = this.begin.dup

        c.x += this.width/2
        c.y += this.height/2

        return c;
    }

    get rect() {
        return {
            tl: new Vector(this.begin.x, this.begin.y),
            tr: new Vector(this.begin.x + this.width, this.begin.y),
            bl: new Vector(this.begin.x, this.begin.y + this.height),
            br: new Vector(this.begin.x + this.width, this.begin.y + this.height)
        }
    }

    update(uid, frame, delta, state) {
        this.updateProps(state.display, frame);

        return true;
    }

    draw(display, frame) {
        this.pen.draw(this, frame);

        this.word.pen.draw(this.word, frame);
        this.bezi.pen.draw(this.bezi, frame);
    }
}

export class PKTextCanvasPen {
    constructor(display) {
        this.display = display;
        this.ctx = display.ctx;
    }

    draw(element, frame) {
        if (element.debugRect) {
            this.ctx.save()
            this.ctx.strokeStyle = 'rgba(255,0,0,1)';
            this.ctx.setLineDash([5,5])
            this.ctx.lineWidth = 1;
            this.ctx.lineCap = 'round';
            this.ctx.beginPath();
            this.ctx.moveTo(element.rect.tl.x, element.rect.tl.y)
            this.ctx.lineTo(element.rect.tr.x, element.rect.tr.y)
            this.ctx.lineTo(element.rect.br.x, element.rect.br.y)
            this.ctx.lineTo(element.rect.bl.x, element.rect.bl.y)
            this.ctx.lineTo(element.rect.tl.x, element.rect.tl.y)
            this.ctx.closePath();
            this.ctx.stroke();
            this.ctx.restore()
        }
    }
}







