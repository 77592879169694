import Vector from './vector.js';
import PKSymbol, {PKSymbolCanvasPen} from "./pk_symbol";
import PKText, {PKTextCanvasPen} from "./pk_text";
export default class PKLogo {

    static STYLE__NEON = "neon"
    static STYLE__DARK = "dark"
    static STYLE__LIGHT = "light"
    static STYLE__MONO = "mono"

    static ALIGNMENT__LEFT = "left"
    static ALIGNMENT__RIGHT = "right"
    static ALIGNMENT__TOP = "top"
    static ALIGNMENT__BOTTOM = "bottom"

    static CONFIG = {
        pen: null,
        content: "polemk",
        begin: new Vector(-5000, -5000),
        height: 40,
        debugRect: false,
        style: PKLogo.STYLE__DARK,
        alignment: PKLogo.ALIGNMENT__BOTTOM
    }

    static DEFAULTS = {

    }

    constructor(config, props) {
        Object.assign(this, PKLogo.CONFIG, config)

        this.props = Object.assign({}, PKLogo.DEFAULTS)

        this.init();

        this.setProps(props);

        this.setStyle(this.style);

        document.fonts.ready.then(function () {
            this.dim();
            this.pos();
        }.bind(this))
    }

    // TODO: do jeito que tá só cria quando inicializa e depende de dimensionamento
    init() {
        this.dim();

        this.symbol = new PKSymbol({
            pen: new PKSymbolCanvasPen(this.pen.display),
            begin: this.outside.dup,
            size: this.symbolHeight,
            style: this.styleForSymbol(this.style)
        }, {});

        this.text = new PKText({
            pen: new PKTextCanvasPen(this.pen.display),
            begin: this.outside.dup,
            height: this.textHeight,
            style: this.styleForText(this.style)
        }, {});

        this.pos();
    }

    dim() {
        this.outside = new Vector(-5000, -5000);
        this.leftSpacing = 0;
        this.spacing = this.height/3;
        this.maxSymbolHeight = 0.5 * this.pen.display.h

        if (this.alignment === PKLogo.ALIGNMENT__LEFT) {
            this.textHeight = this.height;
            this.symbolHeight = this.height;
        } else if (this.alignment === PKLogo.ALIGNMENT__RIGHT) {
            this.textHeight = this.height;
            this.symbolHeight = this.height;
        } else if (this.alignment === PKLogo.ALIGNMENT__BOTTOM) {
            this.spacing = 0.15 * this.height;
            // this.leftSpacing = 0.15 * this.height;
            this.textHeight  = 0.25 * this.height;
            this.symbolHeight = 0.6 * this.height > this.maxSymbolHeight ? this.maxSymbolHeight : 0.6 * this.height;
            this.leftSpacing = (this.height - this.symbolHeight)/2
        } else if (this.alignment === PKLogo.ALIGNMENT__TOP) {
            this.spacing = 0.1 * this.height;
            this.textHeight  = 0.3 * this.height;
            this.symbolHeight = 0.6 * this.height > this.maxSymbolHeight ? this.maxSymbolHeight : 0.6 * this.height;
            this.leftSpacing = (this.height - this.symbolHeight)/2
        }
    }

    pos() {
        if (this.alignment === PKLogo.ALIGNMENT__LEFT) {
            this.symbolBegin = this.begin.dup;
            this.textBegin = new Vector(this.begin.x + this.symbol.width + this.spacing, this.begin.y);
        } else if (this.alignment === PKLogo.ALIGNMENT__RIGHT) {
            this.symbolBegin = new Vector(this.begin.x + this.text.width + this.spacing, this.begin.y);
            this.textBegin = this.begin.dup;
        } else if (this.alignment === PKLogo.ALIGNMENT__BOTTOM) {
            this.symbolBegin = new Vector(this.begin.x + this.leftSpacing, this.begin.y);
            this.textBegin = new Vector(this.begin.x + (this.height - this.text.width)/2, this.begin.y + this.symbol.height + this.spacing);
        } else if (this.alignment === PKLogo.ALIGNMENT__TOP) {
            this.symbolBegin = new Vector(this.begin.x + this.leftSpacing, this.begin.y + this.spacing + this.text.height);
            this.textBegin = new Vector(this.begin.x + (this.height - this.text.width)/2, this.begin.y);
        }

        this.symbol.updateBegin(this.symbolBegin.x, this.symbolBegin.y);
        this.text.updateBegin(this.textBegin.x, this.textBegin.y);
    }

    setAlignment(alignment) {
        if (this.alignment === alignment)
            return;

        this.alignment = alignment;

        this.init();
    }

    setStyle(style) {
        let symbolStyle = this.styleForSymbol(style)
        let textStyle = this.styleForText(style)

        this.symbol.setStyle(symbolStyle);
        this.text.setStyle(textStyle);
    }

    // TODO: revisar como propagar mudanças de props externa
    setProps(props) {
        if (typeof(props) == 'function') {
            this.animator = props;

            Object.assign(this.props, this.animator(this.pen.display, 0))
        } else {
            this.animator = null;

            Object.assign(this.props, props)
        }

        if (!!this.props.symbol)
            this.symbol.setProps(this.props.symbol)

        if (!!this.props.text)
            this.text.setProps(this.props.text)

        this.dim();
    }


    // TODO: como repassar props que vem de function
    updateProps(display, frame) {
        if (this.animator != null) {
            Object.assign(this.props, this.animator(display, frame))

            if (!!this.props.symbol)
                this.symbol.setProps(this.props.symbol)

            if (!!this.props.text)
                this.text.setProps(this.props.text)

            this.dim();
        }
    }

    updateBegin(x, y) {
        if (x != null)
            this.begin.x = x;

        if (y != null)
            this.begin.y = y;

        this.symbol.updateBegin(x,y);
        this.text.updateBegin(x + this.symbol.width + this.spacing, y);

        this.dim();
    }

    styleForSymbol(style) {
        let s = PKSymbol.STYLE__DARK

        if (style === PKLogo.STYLE__LIGHT)
            s = PKSymbol.STYLE__LIGHT

        if (style === PKLogo.STYLE__NEON)
            s = PKSymbol.STYLE__NEON

        if (style === PKLogo.STYLE__MONO)
            s = PKSymbol.STYLE__MONO

        return s;
    }

    styleForText(style) {
        let s = PKText.STYLE__DARK

        if (style === PKLogo.STYLE__LIGHT)
            s = PKText.STYLE__LIGHT

        if (style === PKLogo.STYLE__NEON)
            s = PKText.STYLE__NEON

        if (style === PKLogo.STYLE__MONO)
            s = PKText.STYLE__MONO

        return s;
    }

    getBegin() {
        return this.begin.dup;
    }

    get width() {
        if (this.alignment === PKLogo.ALIGNMENT__RIGHT || this.alignment === PKLogo.ALIGNMENT__LEFT)
            return this.symbol.width + this.text.width + this.spacing

        if (this.alignment === PKLogo.ALIGNMENT__BOTTOM || this.alignment === PKLogo.ALIGNMENT__TOP)
            return this.height
    }

    get center() {
        let c = this.begin.dup

        c.x += this.width/2
        c.y += this.height/2

        return c;
    }

    get rect() {
        return {
            tl: new Vector(this.begin.x, this.begin.y),
            tr: new Vector(this.begin.x + this.width, this.begin.y),
            bl: new Vector(this.begin.x, this.begin.y + this.height),
            br: new Vector(this.begin.x + this.width, this.begin.y + this.height)
        }
    }

    update(uid, frame, delta, state) {
        this.updateProps(state.display, frame);

        return true;
    }

    draw(display, frame) {
        this.pen.draw(this, frame);

        this.symbol.draw(this.pen.display, frame);
        this.text.draw(this.pen.display, frame);
    }
}

export class PKLogoCanvasPen {
    constructor(display) {
        this.display = display;
        this.ctx = display.ctx;
    }

    draw(element, frame) {
        if (element.debugRect) {
            this.ctx.save()
            this.ctx.strokeStyle = 'rgba(0,255,0,1)';
            this.ctx.setLineDash([2,5])
            this.ctx.lineWidth = 1;
            this.ctx.lineCap = 'round';
            this.ctx.beginPath();
            this.ctx.moveTo(element.rect.tl.x, element.rect.tl.y)
            this.ctx.lineTo(element.rect.tr.x, element.rect.tr.y)
            this.ctx.lineTo(element.rect.br.x, element.rect.br.y)
            this.ctx.lineTo(element.rect.bl.x, element.rect.bl.y)
            this.ctx.lineTo(element.rect.tl.x, element.rect.tl.y)
            this.ctx.closePath();
            this.ctx.stroke();
            this.ctx.restore()
        }
    }
}







