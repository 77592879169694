import CanvasDisplay from './canvas_display.js';
import State from './state.js';
import Runner from './runner.js';
import Behavior from './site_behavior.js';

$(function(){
   const runner = new Runner();
   const display = new CanvasDisplay();
   const state = new State(display);
   const b = new Behavior(state, display);

   global.b = b;

   b.init();

   console.info(state);

   runner.run(b.exec.bind(b));

   /* FIXME: wtf essa dependencia */
   display.win.on('resize', function() {
      state.invalidate();

      b.init();
   }.bind(this));
});


