import Vector from './vector.js';
import Bezi, {BeziCanvasPen} from "./bezi";

export default class PKSymbol {
    static STYLE__DARK = "dark"
    static STYLE__LIGHT = "light"
    static STYLE__MONO = "mono"
    static STYLE__NEON = "neon"

    static CONFIG = {
        pen: null,
        begin: new Vector(-500, -500),
        size: 300,
        maxSize: 500,
        debugRect: false,
        style: PKSymbol.STYLE__LIGHT
    }

    static DEFAULTS = {
        bezis: {
            alpha: {
                shadow: false,
                shadowOffsetX: 2,
                shadowOffsetY: 2,
                shadowBlur: 18,
                shadowBlurRatio: 0.095,
                strokeColor: '#00bcf5',
                shadowColor: '#3777ff',
                concavity: {
                    cbx: 0.2,
                    cex: 0.2,
                    cby: 0.1,
                    cey: 0.1,
                    ratio: 0.2,
                }
            },
            beta: {
                shadow: false,
                shadowOffsetX: 2,
                shadowOffsetY: 2,
                shadowBlur: 18,
                shadowBlurRatio: 0.095,
                strokeColor: '#ff367c',
                shadowColor: '#00f5d4',
                concavity: {
                    cbx: -3,
                    cex: -2,
                    cby: 1.5,
                    cey: 1.2,
                    ratio: 2,
                }
            },
            delta: {
                shadow: false,
                shadowOffsetX: 2,
                shadowOffsetY: 2,
                shadowBlur: 18,
                shadowBlurRatio: 0.095,
                strokeColor: '#a0fc00',
                shadowColor: '#efff1a',
                concavity: {
                    cbx: 0.2,
                    cex: 0.2,
                    cby: -0.1,
                    cey: -0.1,
                    ratio: 0.1
                }
            },
            gama: {
                shadow: false,
                shadowOffsetX: 2,
                shadowOffsetY: 2,
                shadowBlur: 18,
                shadowBlurRatio: 0.095,
                strokeColor: '#ff7b00',
                shadowColor: '#ff206e',
                concavity: {
                    cbx: 0.1,
                    cex: 0.2,
                    cby: 0.1,
                    cey: 0.2,
                    ratio: 0.2
                }
            }
        }
    }


    static DARK__PARAMS = {
        bezis: {
            alpha: {
                shadow: false,
                strokeColor: '#00bcf5'
            },
            beta: {
                shadow: false,
                strokeColor: '#ff367c'
            },
            gama: {
                shadow: false,
                strokeColor: '#ff7b00'
            },
            delta: {
                shadow: false,
                strokeColor: '#a0fc00'
            }
        }
    }

    static LIGHT__PARAMS = {
        bezis: {
            alpha: {
                shadow: false,
                strokeColor: '#2c56fe'
            },
            beta: {
                shadow: false,
                strokeColor: '#ff367c'
            },
            gama: {
                shadow: false,
                strokeColor: '#ff7b00'
            },
            delta: {
                shadow: false,
                strokeColor: '#ffd103'
            }
        }
    }

    static MONO__PARAMS = {
        bezis: {
            alpha: {
                shadow: false,
                strokeColor: '#444444'
            },
            beta: {
                shadow: false,
                strokeColor: '#919191'
            },
            gama: {
                shadow: false,
                strokeColor: '#b4b4b4'
            },
            delta: {
                shadow: false,
                strokeColor: '#666666'
            }
        }
    }

    static NEON__PARAMS = {
        bezis: {
            alpha: {
                shadow: true,
                strokeColor: '#00bcf5',
                shadowColor: '#0037ff',
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlurRatio: 0.2
            },
            beta: {
                shadow: true,
                strokeColor: '#ff367c',
                shadowColor: '#ff0303',
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlurRatio: 0.2
            },
            gama: {
                shadow: true,
                strokeColor: '#ff7b00',
                shadowColor: 'rgb(255,94,0)',
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlurRatio: 0.2
            },
            delta: {
                shadow: true,
                strokeColor: '#a0fc00',
                shadowColor: '#53ed00',
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlurRatio: 0.2
            }
        }
    }

    constructor(config, props) {
        Object.assign(this, PKSymbol.CONFIG, config)

        this.props = Object.assign({}, PKSymbol.DEFAULTS)

        this.init();

        this.setProps(props);
    }

    // TODO: do jeito que tá só cria quando inicializa e depende de dimensionamento
    init() {
        this.dim();

        this.alpha = new Bezi({
                pen: new BeziCanvasPen(this.pen.display),
                begin: this.relative.alpha.begin,
                angle: this.relative.alpha.angle,
                length: this.relative.alpha.length,
                after: function(e, d) {}
            }, function (display, frame) {
                return Object.assign(this.props.bezis.alpha,{
                    strokeWidth: this.relative.alpha.width,
                    shadowBlur: this.relative.alpha.shadowBlur
                });
            }.bind(this)
        );

        this.beta = new Bezi({
                pen: new BeziCanvasPen(this.pen.display),
                begin: this.relative.beta.begin,
                angle: this.relative.beta.angle,
                length: this.relative.beta.length,
                after: function(e, d) {}
            }, function (display, frame) {
                return Object.assign(this.props.bezis.beta,{
                    strokeWidth: this.relative.beta.width,
                    shadowBlur: this.relative.beta.shadowBlur
                });
            }.bind(this)
        );

        this.gama = new Bezi({
                pen: new BeziCanvasPen(this.pen.display),
                begin: this.relative.gama.begin,
                angle: this.relative.gama.angle,
                length: this.relative.gama.length,
                after: function(e, d) {}
            }, function (display, frame) {
                return Object.assign(this.props.bezis.gama,{
                    strokeWidth: this.relative.gama.width,
                    shadowBlur: this.relative.gama.shadowBlur
                });
            }.bind(this)
        );

        this.delta = new Bezi({
                pen: new BeziCanvasPen(this.pen.display),
                begin: this.relative.delta.begin,
                angle: this.relative.delta.angle,
                length: this.relative.delta.length,
                after: function(e, d) {}
            }, function (display, frame) {
                return Object.assign(this.props.bezis.delta,{
                    strokeWidth: this.relative.delta.width,
                    shadowBlur: this.relative.delta.shadowBlur
                });
            }.bind(this)
        )
    }

    dim() {
        this.relative = {
            alpha: {
                begin: new Vector(this.begin.x + 0.13*this.size, this.begin.y + 0.0666*this.size/2),
                length: this.size < 80 ? 0.5*this.size : 0.52*this.size,
                angle: -90,
                width: this.size < 80 ? 6 : 0.14*this.size,
                shadowBlur: this.size < 80 ? 18 : this.props.bezis.alpha.shadowBlurRatio * this.size
            },
            beta: {
                begin: new Vector(this.begin.x + 0.2*this.size, this.begin.y + 0.98*this.size),
                length: 0.11*this.size,
                angle: 60,
                width: this.size < 80 ? 4 : 0.095*this.size,
                shadowBlur: this.size < 80 ? 18 : this.props.bezis.beta.shadowBlurRatio * this.size
            },
            gama: {
                begin: new Vector(this.begin.x + (this.size < 80 ? 0.5*this.size: 0.5*this.size), this.begin.y + 0.35*this.size),
                length: this.size < 80 ? 0.5*this.size - 0.0666*this.size/2 : 0.5*this.size - 0.0666*this.size/2,
                angle: 0,
                width: this.size < 80 ? 6 : 0.12*this.size,
                shadowBlur: this.size < 80 ? 18 : this.props.bezis.gama.shadowBlurRatio * this.size
            },
            delta: {
                begin: new Vector( this.begin.x + (this.size < 80 ? 0.5*this.size: 0.5*this.size), this.begin.y + 0.6*this.size),
                length: this.size < 80 ? 0.5*this.size - 0.0666*this.size/2 : 0.5*this.size - 0.0666*this.size/2,
                angle: 0,
                width: this.size < 80 ? 6 : 0.12*this.size,
                shadowBlur: this.size < 80 ? 18 : this.props.bezis.delta.shadowBlurRatio * this.size
            }
        }

        this.rect = {
            tl: new Vector(
                this.begin.x,
                this.begin.y
            ),
            tr: new Vector(
                this.begin.x + this.width,
                this.begin.y
            ),
            bl: new Vector(
                this.begin.x,
                this.begin.y + this.height
            ),
            br: new Vector(
                this.begin.x + this.width,
                this.begin.y + this.height
            )
        }
    }

    // TODO: revisar como propagar mudanças de props externa
    setProps(props) {
        let styleProps = this.getStyleProps(this.style);

        Object.assign(this.props.bezis.alpha, styleProps.bezis.alpha)
        Object.assign(this.props.bezis.beta, styleProps.bezis.beta)
        Object.assign(this.props.bezis.delta, styleProps.bezis.delta)
        Object.assign(this.props.bezis.gama, styleProps.bezis.gama)

        if (typeof(props) == 'function') {
            this.animator = props;

            let frameProps =  this.animator(this.pen.display, 0);

            if (!!frameProps.bezis.alpha)
                Object.assign(this.props.bezis.alpha, frameProps.bezis.alpha)

            if (!!frameProps.bezis.beta)
                Object.assign(this.props.bezis.beta, frameProps.bezis.beta)

            if (!!frameProps.bezis.gama)
                Object.assign(this.props.bezis.gama, frameProps.bezis.gama)

            if (!!frameProps.bezis.delta)
                Object.assign(this.props.bezis.delta, frameProps.bezis.delta)
        } else {
            this.animator = null;

            if (!props.bezis)
                return;

            if (!!props.bezis.alpha)
                Object.assign(this.props.bezis.alpha, props.bezis.alpha)

            if (!!props.bezis.beta)
                Object.assign(this.props.bezis.beta, props.bezis.beta)

            if (!!props.bezis.gama)
                Object.assign(this.props.bezis.gama, props.bezis.gama)

            if (!!props.bezis.delta)
                Object.assign(this.props.bezis.delta, props.bezis.delta)
        }

        this.dim();

        this.alpha.setProps(this.props.bezis.alpha)
        this.beta.setProps(this.props.bezis.beta)
        this.gama.setProps(this.props.bezis.gama)
        this.delta.setProps(this.props.bezis.delta)
    }

    setStyle(style) {
        if (style === this.style)
            return;

        this.style = style;

        this.setProps(this.props);
    }

    // TODO: como repassar props que vem de function
    updateProps(display, frame) {
        if (this.animator != null) {
            Object.assign(this.props, this.animator(display, frame))

            this.alpha.setProps(this.props.bezis.alpha)
            this.beta.setProps(this.props.bezis.beta)
            this.gama.setProps(this.props.bezis.gama)
            this.delta.setProps(this.props.bezis.delta)

            this.dim();
        }
    }

    updateBegin(x, y) {
        if (x != null)
            this.begin.x = x;

        if (y != null)
            this.begin.y = y;

        this.init()
    }

    getStyleProps(style) {
        let props = {}

        if (style === PKSymbol.STYLE__DARK)
            props = PKSymbol.DARK__PARAMS

        if (style === PKSymbol.STYLE__LIGHT)
            props = PKSymbol.LIGHT__PARAMS

        if (style === PKSymbol.STYLE__MONO)
            props = PKSymbol.MONO__PARAMS

        if (style === PKSymbol.STYLE__NEON)
            props = PKSymbol.NEON__PARAMS

        return props
    }

    getBegin() {
        return this.begin.dup;
    }

    get width() {
        return this.size > this.maxSize ? this.maxSize : this.size;
    }

    get height() {
        return this.size > this.maxSize ? this.maxSize : this.size;
    }

    get center() {
        let c = this.begin.dup

        c.x += this.width/2
        c.y += this.height/2

        return c;
    }

    update(uid, frame, delta, state) {
        this.updateProps(state.display, frame);

        return true;
    }

    draw(display, frame) {
        this.pen.draw(this, frame);

        this.alpha.draw(this.pen.display, frame);
        this.beta.draw(this.pen.display, frame);
        this.gama.draw(this.pen.display, frame);
        this.delta.draw(this.pen.display, frame);
    }
}
export class PKSymbolCanvasPen {
    constructor(display) {
        this.display = display;
        this.ctx = display.ctx;
    }

    draw(element, frame) {
        if (element.debugRect) {
            this.ctx.save()
            this.ctx.strokeStyle = 'rgba(255,255,255, 1)';
            this.ctx.setLineDash([2,10])
            this.ctx.lineWidth = 1;
            this.ctx.lineCap = 'round';
            this.ctx.beginPath();
            this.ctx.moveTo(element.rect.tl.x, element.rect.tl.y)
            this.ctx.lineTo(element.rect.tr.x, element.rect.tr.y)
            this.ctx.lineTo(element.rect.br.x, element.rect.br.y)
            this.ctx.lineTo(element.rect.bl.x, element.rect.bl.y)
            this.ctx.lineTo(element.rect.tl.x, element.rect.tl.y)
            this.ctx.closePath();
            this.ctx.stroke();
            this.ctx.restore()
        }
    }
}







