export default class WordBackground {
    static DEFAULTS = {
        color: 'white',
        fill: false,
        stroke: false,
        strokeWidth: 2,
        strokeColor: 'black',
        lineCap: 'round',
        lineDash: null,
        angle: 0,
        radius: 2,
        strokeRadius: null,
        padding: [0, 0, 0, 0],
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowBlur: 3,
        shadow: false,
        shadowColor: 'rgba(255,255,255,0.9)'
    }

    constructor(word, props) {
        this.word = word;
        this.begin = word.getBegin().dup;
        this.props = Object.assign({}, WordBackground.DEFAULTS)
        this.animator = null

        this.setProps(props); // FIXME: n deveria ser init?
    }

    init() {
        this.dim();
    }

    dim() {
        if (this.props.strokeRadius == null)
            this.props.strokeRadius = this.props.radius

        this.props.radius = this.prepareRadius(this.props.radius);
        this.props.strokeRadius = this.prepareRadius(this.props.strokeRadius);
    }

    setProps(props) {
        if (typeof(props) == 'function') {
            this.animator = props;

            Object.assign(this.props, this.animator(this.word.pen.display, 0))
        } else {
            this.animator = null;

            Object.assign(this.props, props)
        }

        this.dim();
    }

    updateProps(display, frame) {
        if (this.animator != null) {
            Object.assign(this.props, this.animator(display, frame))

            this.dim();
        }
    }

    updateBegin(x, y) {
        if (x != null)
            this.begin.x = x - this.offsetLeft;

        if (y != null)
            this.begin.y = y + this.offsetBottom;
    }

    getBegin() {
        return this.begin.dup;
    }

    get has() {
        return this.props.fill || this.props.stroke;
    }

    get offsetLeft() {
        return this.props.padding[1] + (this.props.stroke ? this.props.strokeWidth : 0);
    }

    get offsetRight() {
        return this.props.padding[3] + (this.props.stroke ? this.props.strokeWidth : 0);
    }

    get offsetTop() {
        return this.props.padding[0] + (this.props.stroke ? this.props.strokeWidth : 0);
    }

    get offsetBottom() {
        return this.props.padding[2] + (this.props.stroke ? this.props.strokeWidth : 0);
    }

    get width() {
        return this.word.textWidth + this.offsetLeft + this.offsetRight
    }

    get height() {
        return this.word.textHeight + this.offsetTop + this.offsetBottom
    }

    prepareRadius(rad) {
        let prep;

        if (typeof(rad) == 'number') {
            prep = {
                tl: rad, tr: rad,
                bl: rad, br: rad
            }
        } else if (typeof(rad) == 'object' && !!rad.length) {
            prep = {
                tl: rad[0], tr: rad[1],
                bl: rad[2], br: rad[3]
            }
        } else {
            prep = Object.assign({}, rad);
        }

        return this.saturateRadius(prep);
    }

    saturateRadius(rad) {
        let topMax = this.word.textHeight/2 + this.offsetTop;
        let bottomMax = this.word.textHeight/2 + this.offsetBottom;

        if (rad.tl > topMax)
            rad.tl = topMax

        if (rad.tr > topMax)
            rad.tr = topMax

        if (rad.bl > topMax)
            rad.bl = bottomMax

        if (rad.br > topMax)
            rad.br = bottomMax

        if (rad.tl < 0)
            rad.tl = 0;

        if (rad.tr < 0)
            rad.tr = 0;

        if (rad.bl < 0)
            rad.bl = 0;

        if (rad.br < 0)
            rad.br = 0;

        return rad;
    }



    update(uid, frame, delta, state) {
        this.updateProps(state.display, frame);

        return true;
    }

    draw(display, frame) {
        let ctx = this.word.pen.ctx;

        ctx.save();
        ctx.fillStyle = this.props.color;
        ctx.translate(this.begin.x + this.width / 2, this.begin.y - this.height / 2);
        ctx.rotate(-this.props.angle * (Math.PI / 180));

        if (this.props.shadow) {
            ctx.shadowOffsetX = this.props.shadowOffsetX;
            ctx.shadowOffsetY = this.props.shadowOffsetY;
            ctx.shadowBlur = this.props.shadowBlur;
            ctx.shadowColor = this.props.shadowColor;
        }

        if (this.props.fill) {
            let r = this.props.radius

            ctx.beginPath()
            ctx.moveTo(-this.width / 2,  -this.height/2 + r.tl )
            ctx.arcTo(-this.width/2, this.height/2, -this.width/2 + r.bl, this.height/2, r.bl)
            ctx.arcTo(this.width/2 , this.height/2, this.width/2, this.height/2 - r.br , r.br)
            ctx.arcTo(this.width/2, -this.height/2, this.width/2 - r.tr , -this.height/2, r.tr)
            ctx.arcTo(-this.width/2, -this.height/2, -this.width/2, -this.height/2 + r.tl, r.tl)
            ctx.fill();
        }

        if (this.props.stroke) {
            let r = this.props.strokeRadius

            ctx.strokeStyle = this.props.strokeColor;
            ctx.strokeWidth = this.props.strokeWidth;
            ctx.lineWidth = this.props.strokeWidth;
            ctx.lineCap = this.props.lineCap;

            if (!!this.props.lineDash)
                ctx.setLineDash(this.props.lineDash);

            ctx.beginPath()
            ctx.moveTo(-this.width / 2,  -this.height/2 + r.tl )
            ctx.arcTo(-this.width/2, this.height/2, -this.width/2 + r.bl, this.height/2, r.bl)
            ctx.arcTo(this.width/2 , this.height/2, this.width/2, this.height/2 - r.br , r.br)
            ctx.arcTo(this.width/2, -this.height/2, this.width/2 - r.tr , -this.height/2, r.tr)
            ctx.arcTo(-this.width/2, -this.height/2, -this.width/2, -this.height/2 + r.tl, r.tl)
            ctx.stroke();
        }

        ctx.restore();

        /* rect */
        // ctx.save();
        // ctx.strokeStyle = 'rgb(250,45,107)';
        // ctx.strokeWidth = 5;
        // ctx.lineWidth = 2;
        // ctx.setLineDash([5, 10]);
        // ctx.beginPath();
        // ctx.moveTo(this.begin.x, this.begin.y)
        // ctx.lineTo(this.begin.x + this.width, this.begin.y)
        // ctx.lineTo(this.begin.x + this.width, this.begin.y - this.height)
        // ctx.lineTo(this.begin.x, this.begin.y - this.height)
        // ctx.lineTo(this.begin.x, this.begin.y)
        // ctx.stroke();
        // ctx.restore();
    }
}