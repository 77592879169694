export default class Vector {
   constructor(x, y) {
      this.x = x;
      this.y = y;
   }

   add(vector) {
      this.x += vector.x;
      this.y += vector.y;
      
      return this;
   }
   
   subtract(vector) {
      this.x -= vector.x;
      this.y -= vector.y;
   
      return this;
   }
   
   multiply(scalar) {
      this.x *= scalar;
      this.y *= scalar;
   
      return this;
   }
   
   dotProduct(vector) {
      return this.x * vector.x + this.y * vector.y;
   }
   
   get magnitude() {
      return Math.sqrt(this.x * this.x + this.y * this.y);
   }
   
   get direction() {
      return Math.atan2(this.x, this.y);
   }
   
   get immutable() {
      return new ImmutableVector(this.x, this.y);
   }

   get dup() {
      return new Vector(this.x, this.y);
   }

   projection(angle, length) {
      let x = this.x + length * Math.cos(angle*Math.PI/180)
      let y = this.y + length * Math.sin(angle*Math.PI/180)

      return new Vector(x, y)
   }
}

export class ImmutableVector {
   constructor(x, y) {
      this.x = x;
      this.y = y;
   }
   
   add(vector) {
      return new ImmutableVector(this.x + vector.x, this.y + vector.y);
   }
   
   subtract(vector) {
      return new ImmutableVector(this.x - vector.x, this.y - vector.y);
   }
   
   multiply(scalar) {
      return new ImmutableVector(this.x * scalar, this.y * scalar);
   }
   
   dotProduct(vector) {
      return this.x * vector.x + this.y * vector.y;
   }
   
   get magnitude() {
      return Math.sqrt(this.x * this.x + this.y * this.y);
   }
   
   get direction() {
      return Math.atan2(this.x, this.y);
   }

   get dup() {
      return new ImmutableVector(this.x, this.y);
   }

   projection(angle, length) {
      let x = this.x + length * Math.cos(angle*Math.PI/180)
      let y = this.y + length * Math.sin(angle*Math.PI/180)

      return new ImmutableVector(x, y)
   }
}