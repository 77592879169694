import Vector from './vector.js';

export default class Star {
   constructor(config) {
      Object.assign(
         this,
  {
            pen: null,
            type: 'star',
            position: new Vector(0,0),
            velocity: new Vector((2*Math.random() - 1)*0.07,(2*Math.random() - 1)*0.07),
            acceleration: new Vector((2*Math.random() - 1)*0.001, (2*Math.random() - 1)*0.001),
            radius: Math.random()*1,
            color: 'white',
            state: 'idle',
            memento: null
         },
         config
      )

      this.origin = this.position.immutable;
   }

   onEvent(event, state) {
      if (event == 'keydown__arrow_up') {
         this.state = 'down';
      } else if (event == 'keydown__arrow_down') {
         this.state = 'up';
      } else if (event == 'keydown__arrow_left') {
         this.state = 'right';
      } else if (event == 'keydown__arrow_right') {
         this.state = 'left';
      } else if (event == 'keydown__space') {
         this.state = 'idle';
      }
   }

   update(uid, frame, delta, state) {
      if (this.state == 'idle')
         this.idle(frame, state);
      else if (this.state == 'up')
         this.up(frame, state);
      else if (this.state == 'down')
         this.down(frame, state);
      else if (this.state == 'left')
         this.left(frame, state);
      else if (this.state == 'right')
         this.right(frame, state);

      return true;
   }

   left(frame, state) {
      /* poderia ser um método que troca estado */
      if (this.memento != 'left') {
         this.velocity = new Vector(
             -(Math.random() + 1)*5,
             (2*Math.random() - 1)*0.2,
         )

         this.acceleration = new Vector(
             -Math.random()*0.5,
             0
         )

         this.memento = 'left';
      }

      if (this.position.y + this.radius >= state.display.h || this.position.y - this.radius <= 0) {
         this.velocity.y *= -1;
      }

      if (this.position.x - this.radius <= 0) {
         this.position.x = state.display.w + 10;
         this.velocity.x = -5;
         this.position.y = Math.random()*state.display.h;
         this.radius = 0.5*Math.random();
      }

      if (this.velocity.x < -25)
         this.velocity.x = -15;

      this.velocity.add(this.acceleration);
      this.position.add(this.velocity);

      if ((frame % 10 === 0)) {
         this.radius += (2*Math.random() - 1)*((1 - this.position.x/state.display.w))/3;

         if (this.radius >= 0.8)
            this.radius = 0.8;

         if (this.radius <= 0.1)
            this.radius = 0.1;
      }
   }

   right(frame, state) {
      /* poderia ser um método que troca estado */
      if (this.memento != 'right') {
         this.velocity = new Vector(
             (Math.random() + 1)*5,
             (2*Math.random() - 1)*0.2,
         )

         this.acceleration = new Vector(
             Math.random()*0.5,
             0
         )

         this.memento = 'right';
      }

      if (this.position.y + this.radius >= state.display.h || this.position.y - this.radius <= 0) {
         this.velocity.y *= -1;
      }

      if (this.position.x - this.radius >= state.display.w) {
         this.position.x = -10;
         this.velocity.x = 5;
         this.position.y = Math.random()*state.display.h;
         this.radius = 0.5*Math.random();
      }

      if (this.velocity.x > 25)
         this.velocity.x = 15;

      this.velocity.add(this.acceleration);
      this.position.add(this.velocity);

      if ((frame % 10 === 0)) {
         this.radius += (2*Math.random() - 1)*((1 - this.position.x/state.display.w))/3;

         if (this.radius >= 0.8)
            this.radius = 0.8;

         if (this.radius <= 0.1)
            this.radius = 0.1;
      }
   }

   down(frame, state) {
      /* poderia ser um método que troca estado */
      if (this.memento != 'down') {
         this.velocity = new Vector(
             (2*Math.random() - 1)*0.2,
             (Math.random() + 1)*5
         )

         this.acceleration = new Vector(
             0,
             Math.random()*0.5
         )

         this.memento = 'down';
      }

      if (this.position.x + this.radius >= state.display.w || this.position.x - this.radius <= 0) {
         this.velocity.x *= -1;
      }

      if (this.position.y - this.radius >= state.display.h) {
         this.position.y = -10;
         this.velocity.y = 5;
         this.position.x = Math.random()*state.display.w;
         this.radius = 0.5*Math.random();
      }

      if (this.velocity.y > 25)
         this.velocity.y = 15;

      this.velocity.add(this.acceleration);
      this.position.add(this.velocity);

      if ((frame % 10 === 0)) {
         this.radius += (2*Math.random() - 1)*((1 - this.position.y/state.display.h))/3;

         if (this.radius >= 0.8)
            this.radius = 0.8;

         if (this.radius <= 0.1)
            this.radius = 0.1;
      }
   }

   up(frame, state) {
      /* poderia ser um método que troca estado */
      if (this.memento != 'up') {
         this.velocity = new Vector(
             (2*Math.random() - 1)*0.2,
             -(Math.random() + 1)*5
         )

         this.acceleration = new Vector(
             0,
             -Math.random()*0.5
         )

         this.memento = 'up';
      }

      if (this.position.x + this.radius >= state.display.w || this.position.x - this.radius <= 0) {
         this.velocity.x *= -1;
      }

      if (this.position.y - this.radius <= 0) {
         this.position.y = state.display.h + 10;
         this.velocity.y = -10;
         this.position.x = Math.random()*state.display.w;
         this.radius = 0.5*Math.random();
      }

      if (this.velocity.y < -25)
         this.velocity.y = -15;

      this.velocity.add(this.acceleration);
      this.position.add(this.velocity);

      if ((frame % 10 === 0)) {
         this.radius += (2*Math.random() - 1)*((this.position.y/state.display.h))/3;

         if (this.radius >= 0.8)
            this.radius = 0.8;

         if (this.radius <= 0.1)
            this.radius = 0.1;
      }
   }

   idle(frame, state) {
      if (this.memento != 'idle') {
         this.origin = this.position.immutable

         this.velocity.x = (2*Math.random() - 1)*0.07;
         this.velocity.y = (2*Math.random() - 1)*0.07;

         this.acceleration = new Vector(
             0,
             -0
         )

         this.memento = 'idle';
      }

      let diff = this.origin.subtract(this.position)
      let distanceX = diff.x * diff.x
      let distanceY = diff.y * diff.y

      if (this.position.x + this.radius >= state.display.w || this.position.x - this.radius <= 0 || distanceX > 100) {
         this.velocity.x *= -1;
      }

      if (this.position.y + this.radius > state.display.h || this.position.y - this.radius <= 0 || distanceY > 100) {
         this.velocity.y *= -1;
      }

      this.position.add(this.velocity);

      // if ((frame % 600 === 0) && (Math.random() > 0.7)) {
      //    // this.radius += (Math.random()*2 - 1)*0.05
      //
      //    if (this.radius >= 1.2)
      //       this.radius = 1;
      //
      //    if (this.radius <= 0.2)
      //       this.radius = 0.2;
      // }
   }

   draw(frame) {
      this.pen.draw(this, frame);
   }

   get area() {
      return 4 * Math.PI * this.radius * this.radius;
   }
}

export class StarCanvasPen {
   constructor(display) {
      this.display = display;
      this.ctx = display.ctx;
   }

   draw(element, frame) {
      if (this.ctx.fillStyle !== element.color)
         this.ctx.fillStyle = element.color;

      this.ctx.beginPath();
      this.ctx.arc(element.position.x, element.position.y, element.radius, 0, 2*Math.PI, true)
      this.ctx.fill();
      // this.ctx.fillRect(element.position.x, element.position.y, element.radius, element.radius)
   }
}