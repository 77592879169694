export default class State {
   constructor(display) {
      this.display = display;
      this.elements = [];
      this.invalidating = false;
   }

   invalidate() {
      this.invalidating = true;

      this.display.invalidate();

      this.elements = [];

      this.invalidating = false;
   }
   
   update(frame, delta) {
      const uid = Math.floor(Math.random() * 1000000)

      if (this.invalidating) {
         return;
      }

      for (let e of this.elements) {
         if (!e.update(uid, frame, delta, this)) {
            this.elements.splice(this.elements.indexOf(e), 1)
         }
      }
   }
}