import 'jquery';
import Rails from '@rails/ujs';
import './sandbox';


import AgentHelper from "/var/www/apps/lvw/engines/ux_kit19/app/frontend/util/js/helpers/agent.js";

Rails.start();
global.Rails = Rails
global.isMobile = AgentHelper
