import Vector from './vector.js';
import WordCustomizer from './word_customizer';

export default class Word {
    static DEFAULTS = {
        font: "Livetat SOF",
        weight: 500,
        size: 40,
        letterSpacing: 0,

        stroke: false,
        strokeWidth: 2,
        strokeColor: 'rgb(255,255,255)',

        fill: true,
        color: 'rgb(255,255,255)',

        shadow: false,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowBlur: 0,
        shadowColor: 'rgba(255,255,255,0.7)',
    }

    static CONFIG = {
        pen: null,
        type: 'word',
        begin: new Vector(0, 0),
        line: 1,
        wrapX: true,
        wrapY: false,
        content: "technology",
    }

    constructor(config, props) {
        Object.assign(this, Word.CONFIG, config)

        this.customizer = new WordCustomizer(this, {}); /* TODO: inicializa o customizer que tenta dimensionar mas n tem props da word.. */
        this.props = Object.assign({}, Word.DEFAULTS)

        this.setProps(props);
    }

    init() {
        //this.dim();
    }

    dim() {
        let ctx = this.pen.ctx;

        ctx.save();

        ctx.font = this.props.weight + " " + this.props.size + "px " + this.props.font;
        ctx.fillStyle = this.props.color;
        ctx.strokeStyle = this.props.strokeColor;
        ctx.lineWidth = this.props.strokeWidth;
        ctx.letterSpacing = this.props.letterSpacing + 'px';

        let m = ctx.measureText(this.content);

        this.textSpacing = ctx.measureText(" ").width;
        this.textWidth = m.width;
        this.textHeight = this.props.size * 2 / 3;

        ctx.restore();

        this.customizer.dim();
    }

    measure(content) {
        let ctx = this.pen.ctx;

        ctx.save();

        ctx.font = this.props.weight + " " + this.props.size + "px " + this.props.font;
        ctx.fillStyle = this.props.color;
        ctx.strokeStyle = this.props.strokeColor;
        ctx.lineWidth = this.props.strokeWidth;
        ctx.letterSpacing = this.props.letterSpacing + 'px';

        let m = ctx.measureText(content);

        ctx.restore();

        return m.width;
    }

    setProps(props) {
        this.animator = null

        if (typeof(props) == 'function') {
            this.animator = props;

            Object.assign(this.props, this.animator(this.pen.display, 0))
        } else {
            this.animator = null;

            Object.assign(this.props, props)
        }

        this.customizer.setProps(this.props);

        this.dim();
    }

    setBorder(props) {
        this.customizer.setProps({border: props});
    }

    setBackground(props) {
        this.customizer.setProps({background: props});
    }

    setCustom(custom) {
        this.customizer.setCustom(custom);
    }

    updateProps(display, frame) {
        if (this.animator != null) {
            Object.assign(this.props, this.animator(display, frame))

            this.dim();
        }
    }

    updateBegin(x, y) {
        if (x != null)
            this.begin.x = x;

        if (y != null)
            this.begin.y = y;

        this.customizer.updateBegin(x, y);
    }

    getBegin() {
        return this.begin.dup;
    }

    get textBegin() {
        let bx = this.begin.x;
        let by = this.begin.y;

        if (this.wrapX) {
            bx += this.customizer.offsetLeft;
        }

        if (this.wrapY) {
            by -= this.customizer.offsetBottom;
        }

        return new Vector(bx, by);
    }

    get spacing() {
        return this.customizer.spacing;
    }

    get width() {
        return this.wrapX ? this.customizer.width : this.textWidth;
    }

    get height() {
        return this.wrapY ? this.customizer.height : this.textHeight;
    }

    get center() {
        return this.customizer.center;
    }

    get end() {
        return this.customizer.end;
    }



    update(uid, frame, delta, state) {
        this.updateProps(state.display, frame);

        this.customizer.update(uid, frame, delta, state);

        return true;
    }

    draw(display, frame) {
        this.customizer.draw(display, frame);

        this.pen.draw(this, frame);
    }
}

export class WordCanvasPen {
    constructor(display) {
        this.display = display;
        this.ctx = display.ctx;
    }

    draw(element, frame) {
        var ref = element.textBegin;

        this.ctx.save()
        this.ctx.font = element.props.weight + " " + element.props.size + "px " + element.props.font;
        this.ctx.strokeStyle = element.props.strokeColor;
        this.ctx.lineWidth = element.props.strokeWidth;
        this.ctx.fillStyle = element.props.color;
        this.ctx.letterSpacing = element.props.letterSpacing + 'px';

        if (element.props.shadow) {
            this.ctx.shadowOffsetX = element.props.shadowOffsetX;
            this.ctx.shadowOffsetY = element.props.shadowOffsetY;
            this.ctx.shadowBlur = element.props.shadowBlur;
            this.ctx.shadowColor = element.props.shadowColor;
        }

        if (element.props.fill) {
            this.ctx.fillText(element.content, ref.x, ref.y)
        }

        if (element.props.stroke) {
            this.ctx.strokeText(element.content, ref.x, ref.y)
        }

        this.ctx.restore();

        /* rect */
        // this.ctx.save();
        // this.ctx.strokeStyle = 'rgb(250,45,137)';
        // this.ctx.lineWidth = 2;
        // this.ctx.setLineDash([5, 10]);
        // this.ctx.beginPath();
        // this.ctx.moveTo(ref.x, ref.y)
        // this.ctx.lineTo(ref.x + element.textWidth, ref.y)
        // this.ctx.lineTo(ref.x + element.textWidth, ref.y - element.textHeight)
        // this.ctx.lineTo(ref.x, ref.y - element.textHeight)
        // this.ctx.lineTo(ref.x, ref.y)
        // this.ctx.stroke();
        // this.ctx.restore();
    }
}







