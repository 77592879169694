export default class CanvasDisplay {
   constructor(doc = document, win = $(window), target = null) {
      // if (isMobile.any()) {
      //    var width = doc.documentElement.clientWidth * window.devicePixelRatio;
      //    let viewport = doc.querySelector("meta[name=viewport]");
      //    viewport.setAttribute('content', 'width=' + width + ', minimum-scale: 1');
      //    doc.documentElement.style.transform = 'scale( 1 / window.devicePixelRatio )';
      //    doc.documentElement.style.transformOrigin = 'top left';
      // }
      
      this.canvas = doc.createElement('canvas');
      this.w = win.width();
      this.h = win.height();
      this.background = '#ffffff'
      this.win = win;

      this.ratio = window.devicePixelRatio;

      this.canvas.width = this.w * this.ratio;
      this.canvas.height = this.h * this.ratio;
      this.canvas.style.width = this.w + 'px';
      this.canvas.style.height = this.h + 'px';

      if (!!target && target.length > 0)
         target.append(this.canvas);
      else
         doc.body.appendChild(this.canvas);
      
      this.ctx = this.canvas.getContext('2d', { willReadFrequently: false });
      this.ctx.scale(this.ratio, this.ratio);

      this.frame = 0;
   }

   invalidate() {
      this.w = this.win.width();
      this.h = this.win.height();
      this.canvas.width = this.w * this.ratio;
      this.canvas.height = this.h * this.ratio;
      this.canvas.style.width = this.w + 'px';
      this.canvas.style.height = this.h + 'px';

      this.ctx.scale(this.ratio, this.ratio);
   }
   
   sync(state, frame) {
      this.frame = frame;
      this.clear();
      this.draw(state.elements, frame);
   }
   
   draw(elements, frame) {
      for (let e of elements) {
         e.draw(this, frame);
      }
   }
   
   clear() {
      // this.ctx.fillStyle = 'rgba(255,255,255,0.05)';
      // this.ctx.fillRect(0,0, this.w, this.h)
      this.ctx.clearRect(0, 0, this.w, this.h);
   }
   
   randomX() {
      return Math.random() * this.w;
   }
   
   randomY() {
      return Math.random() * this.h;
   }
}