export default class Runner {
   constructor() {}
   
   run(runnable) {
      let lastTime = null;
      let frame = 0;
      
      const r = time => {
         if (lastTime !== null) {
            const delta = Math.min(100, time - lastTime) / 1000;
   
            if (runnable(frame, delta) === false)
               return;
         }
   
         frame++;
         lastTime = time;
         requestAnimationFrame(r);
      };
      
      requestAnimationFrame(r);
   }
}